<div class="container link">
  <div class="row">
    <div class="col-md-6 ">
      <div class=" mb-5 link-card">
        <div class="row">
          <div class="link-card__img col-md-6 col-12">
            <img src="{{'./assets/themes/'+ _config.settings.tenant + '/images/register-company.png'}}" alt="">
          </div>
          <div class="link-card__desc col-md-5 col-12 ">
            <h3>{{ 'menu.registeredCompanies' | translate }}</h3>
            <p> {{ 'banner.companiesBanner.message' | translate }} </p>
            <a class="btn btn-primary mt-3 " href="#" (click)="goToSearchPart()">
              {{ 'common_inputs.search' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-12 ">
      <div class=" mb-5 link-card">
        <div class="row">
          <div class="link-card__img col-md-6 col-12 ">
            <img src="{{'./assets/themes/'+ _config.settings.tenant + '/images/verify-garment.png'}}" alt="">
          </div>
          <div class="link-card__desc col-md-5 col-12 ">
            <h3 class="ml-3">{{ 'menu.checkPiece' | translate }} </h3>
            <p class="ml-4"> {{ 'banner.partBanner.message' | translate }} </p>
            <a class="btn btn-primary mt-3 " href="#" (click)="goToVeriFyPart()">
              {{ 'common_inputs.search' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
