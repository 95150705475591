import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { map }  from 'rxjs/operators'
import { ConfigService } from 'src/app/services/config.service';
import { GLOBAL } from 'src/app/shared/parametrosGlobales';


@Injectable()
export class BuscarEmpresaService {

  public url: string = ConfigService.settings.env;
  public path: string;

  constructor(public _http: Http) { }

  public findListaEmpresa(criterioDeBusquedaEmpresa) {
    
    this.path = GLOBAL.empresa.getEmpresaPorActividad;
    return this._http.post(this.url + this.path, criterioDeBusquedaEmpresa).pipe(map(res => res.json()));
  }
}

