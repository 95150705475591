<footer class="footer">
  
    <div class="footer-top">
       <span class="text-white">
        Rua General Bertoldo Klinger, 69 Vl - Paulicéia, Tel: +55 11 2666-8800 | Cep: 09688 - 000    São Bernardo do Campo - São Paulo – SP<br/>
        <a *ngIf="urlCredenciamento != null" href="{{urlRegulamento}}" target="_blank" class="text-white"><u>{{ 'footer.credenciamento' | translate }}</u> | </a>
        <a *ngIf="urlRegulamento != null" href="{{urlRegulamento}}" target="_blank" class="text-white"><u>{{ 'footer.regulamentacionPieza' | translate }}</u> | </a>
        <a *ngIf="urlCartilha != null" href="{{urlCartilha}}" target="_blank" class="text-white"><u>{{ 'footer.cartilhaCredenciamento' | translate }}</u></a>
    </span>
    </div>
    <div class="footer-copy">
        <img src="./assets/images/logo-thomas-gref-quipux.svg" alt="Thomas Greg & Quipux" class="mr-2">
        <span class="text-white ml-2">© 2022 All rights reserved</span>

    </div>

</footer>