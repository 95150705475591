import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { map }  from 'rxjs/operators'
import { ConfigService } from 'src/app/services/config.service';
import { GLOBAL } from '../../../shared/parametrosGlobales';

@Injectable()
export class RegistrarDenunciaService {

    public url: string = ConfigService.settings.env;
    public path: string;

    constructor(public _http: Http) {
    }

    public guardarDenuncia(denuncia, listaAnexos) {
        /*const formData: FormData = new FormData();

        if (listaAnexos.length > 0) {
            for (let i = 0; i < listaAnexos.length; i++) {
                formData.append('file', listaAnexos[i]);
            }
        } else {
            formData.append('file', null);
        }
        formData.append('denuncia', JSON.stringify(denuncia));*/
        this.path = GLOBAL.denuncia.registrar.saveDenuncia
        return this._http.post(this.url + this.path, denuncia).pipe(map(res => res.json()));
    }
}