import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CriteroBusquedaDenunciaModel } from 'src/app/model/denuncia/criterioDeBusquedaDenuncia';
import { ResultadoDenunciaModel } from 'src/app/model/denuncia/resultadoDenuncia';
import { BuscarDenunciaService } from 'src/app/pages/denuncia/buscar-denuncia/buscar-denuncia.service';
import { RecaptchaService } from 'src/app/services/recaptcha.service';
import Swal from 'sweetalert2';
import { DataService } from '../../../shared/data.service';


@Component({
  selector: 'app-result-complaint',
  templateUrl: './buscar-denuncia.component.html',
  styleUrls: ['./buscar-denuncia.component.scss'],
  providers: [BuscarDenunciaService, RecaptchaService]
})
export class BuscarDenunciaComponent implements OnInit {

  public resultadoDenuncia: ResultadoDenunciaModel;
  criterioBusquedaDenuncia: CriteroBusquedaDenunciaModel

  resuldenuncia: FormGroup;
  constructor(
    private _buscarDenunciaService: BuscarDenunciaService,
    private translate: TranslateService,
    private _data: DataService,
    private _recaptchaService: RecaptchaService) {

    translate.setDefaultLang('pt');
    this.criterioBusquedaDenuncia = new CriteroBusquedaDenunciaModel("", "");

  }

  ngOnInit() {
    this._data.currentCodigoDenuncia.subscribe(codigoDenuncia => this.criterioBusquedaDenuncia.codigoDenuncia = codigoDenuncia);
    this._data.currentPin.subscribe(pin => this.criterioBusquedaDenuncia.pin = pin);
    this.findDenunciaPorCodigoPin();
  }

  get f() { return this.resuldenuncia.controls; }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  findDenunciaPorCodigoPin() {
    this._buscarDenunciaService.findDenunciaPorCodigoPin(this.criterioBusquedaDenuncia).subscribe(
      response => {
        if (response != null) {
          this.resultadoDenuncia = response;
        } else {
          this.translate.get('resultComplaint.sweetAlert.title').subscribe((title: string) => {
            this.translate.get('resultComplaint.sweetAlert.message').subscribe((message: string) => {
              Swal.fire(title, message, "error");
            });
          });
        }
      },
      error => {
        console.log(error);
      });
  }

  onClickNuevaBusquedad(captcha: string) {
    this._recaptchaService.VerificarRespuesta(captcha).then((
      response: any) => {
      if (response) {
        this.findDenunciaPorCodigoPin();
      }
      grecaptcha.reset();
    },
      error => {
        console.log(error);
      }
    );
  }

}
