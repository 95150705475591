import Swal from 'sweetalert2';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FileUploader } from "ng2-file-upload";
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/services/global.service';
import { RecaptchaService } from 'src/app/services/recaptcha.service';
import { RegistrarDenunciaService } from './registrar-denuncia.service';
import { DenunciaModel } from 'src/app/model/denuncia/denuncia';
import { Evidencias } from 'src/app/model/denuncia/evidencias';
import { cepPopUpComponent } from 'src/app/components/popup/cep/cepDialog.component';
import { TipoDocumentoModel } from 'src/app/model/denuncia/tipoDocumento';
import { CiudadModel } from 'src/app/model/denuncia/ciudad';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
export interface Estados {
  value: string;
  viewValue: string;
}
export interface Ciudades {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-registrar-denuncia',
  templateUrl: './registrar-denuncia.component.html',
  styleUrls: ['./registrar-denuncia.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    GlobalService,
    RecaptchaService,
    RegistrarDenunciaService
  ],
})
export class RegistrarDenunciaComponent implements OnInit {
  panelOpenState = false;
  //validation email
  email = new FormControl('', [Validators.email]);
  controlCiudades = new FormControl();
  controlCep = new FormControl();
  controlNumeroDirreccion = new FormControl();
  controlDirreccion = new FormControl();
  controlDepartamento = new FormControl();

  getErrorMessage() {
    return this.email.hasError('email') ? 'commonErrors.invalidEmail' :
      '';
  }

  public denunciaModel: DenunciaModel;
  public ListaCiudad = [];
  public enviado = false;

  registrarDenuncia: FormGroup;
  submitted = false;
  ListaDocumentos: TipoDocumentoModel;
  filteredCiudad: Observable<CiudadModel[]>;
  listaAnexos = [];
  selectedCiudad: string;

  public filesToUpload;
  constructor(
    private translate: TranslateService,
    private _globalService: GlobalService,
    private _registrarDenunciaService: RegistrarDenunciaService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private fb: FormBuilder,
    private _recaptchaService: RecaptchaService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    translate.setDefaultLang('pt');
    this.denunciaModel = new DenunciaModel(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
  }
  uploadForm: FormGroup;

  public uploader: FileUploader = new FileUploader({
    isHTML5: true
  });
  ngOnInit() {

    this.findListaDocumento();
    this.findCiudadesConDepartamentos();
    this.registrarDenuncia = this.formBuilder.group({
      denunciante: ['', Validators.required]
    });
    this.uploadForm = this.fb.group({
      document: [null, null],
      type: [null, Validators.compose([Validators.required])]
    });
  }

  get f() { return this.registrarDenuncia.controls; }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  findListaDocumento() {
    this._globalService.findListaDocumento().then((response: any) => {
      this.ListaDocumentos = response;
    },
      error => {
      }
    );
  }

  findCiudadesConDepartamentos() {
    this._globalService.findCiudadesConDepartamentos().then((datos: any) => {
      this.ListaCiudad = datos;
      this.filteredCiudad = this.controlCiudades.valueChanges
        .pipe(
          startWith(''),
          map(ciudad => ciudad ? this._filterCiudades(ciudad) : this.ListaCiudad.slice())
        );
    },
      error => {
      });
  }

  openCepDialog(): void {
    const dialogRef = this.dialog.open(cepPopUpComponent, {
      width: '50vw',
      data: {}
    });
    dialogRef.afterClosed().subscribe((result : any) => {
      if (result != null) {
        this._globalService.findDepartamentoBySigla(result.abreviatura).then((departamento => {
          this.denunciaModel.cep = result.cep.replace("-", "");
          this.denunciaModel.barrio = result.bairro
          this.denunciaModel.nombreCiudad = result.ciudad;
          this.selectedCiudad = result.cidade;
          this.denunciaModel.direccion = result.direccion;
          this.denunciaModel.complemento = result.complemento;
          this.denunciaModel.nombreDepartamento = departamento.nombreDepartamento;
          this.denunciaModel.idDepartamento = departamento.idEstado;
          //this.denunciaModel.idBarrio = result.idBarrio;
          this._globalService.findListaCiudad(departamento.idEstado).then((data: []) => {
            this.ListaCiudad = data           
            for(let i = 0; i < this.ListaCiudad.length;i++){
              if(this.ListaCiudad[i].nombreCiudad === result.cidade){
                this.denunciaModel.idCiudad = this.ListaCiudad[i].idCiudad
              }
            }
          })
        }))
      }
    });
  }

  uploadSubmit() {
    this.denunciaModel.listaEvidencias = [];
    for (var i = 0; i < this.uploader.queue.length; i++) {
      let fileItem = this.uploader.queue[i]._file;
      if (fileItem.size > 1000000) {
        this.translate.get('complaint.sweetAlert.title').subscribe((title: string) => {
          this.translate.get('complaint.sweetAlert.message').subscribe((message: string) => {
            Swal.fire(title, message, "error");
          });
        });
        this.uploader.queue.splice(i, 1);
      } else {
        this.getBase64(fileItem);
      }
    }
  }

  getBase64(file) {
    var myReader: FileReader = new FileReader();
    var evidencia: Evidencias = new Evidencias(null, null);
    myReader.onloadend = (e) => {
      evidencia.nroDocumento = file.name;
      evidencia.file = (String)(myReader.result).split('base64,')[1];
      this.denunciaModel.listaEvidencias.push(evidencia);
    }
    myReader.readAsDataURL(file);
  }

  onSelectDocument(document) {
    this.denunciaModel.idTipoIdentificacion = document.idDocumentoIdentidad;
  }

  onSelectCiudad(ciudad) {
    this.denunciaModel.idCiudad = ciudad.idCiudad;
    this.denunciaModel.nombreCiudad = ciudad.nombreCiudad;
    this.denunciaModel.idDepartamento = ciudad.idDepartamento;
    this.denunciaModel.nombreDepartamento = ciudad.nombreDepartamento;
  }

  onKey(event: any) { // without type info
    return false;
  }

  guardarDenuncia(captchaResponse: string) {
    if (this.denunciaModel.idCiudad == null || this.denunciaModel.cep == null || this.denunciaModel.numero == '' || this.denunciaModel.numero == null || this.denunciaModel.denuncia == '' || this.denunciaModel.denuncia == null) {
      this.translate.get('complaint.missingInfo.title').subscribe((title: string) => {
        this.translate.get('complaint.missingInfo.message').subscribe((message: string) => {
          Swal.fire(title, message, "warning");
        });
      });
      grecaptcha.reset();
    } else {
      this.spinner.show();
      this.enviado = true;
      this._recaptchaService.VerificarRespuesta(captchaResponse).then((
        response: any) => {
        if (response) {
          this._registrarDenunciaService.guardarDenuncia(this.denunciaModel, this.listaAnexos).subscribe(response => {
            this.translate.get('complaint.saveComplaint.title').subscribe((title: string) => {
              this.translate.get('complaint.saveComplaint.message', { idSolicitudAdm: response.idSolicitudProcesoAdm, pin: response.codigoPin }).subscribe((message: string) => {
                Swal.fire(title, message, "success").then((value) => {
                  this.router.navigate(['home'])
                });
              });
            });

            this.spinner.hide();
          },
            error => {
              this.spinner.hide();
              this.enviado = false;
            }
          );
        } else {
          this.spinner.hide();
        }
        this.enviado = false;
        grecaptcha.reset();
      },
        error => {
          this.spinner.hide();
          this.enviado = false;
        }
      );
    }
  }

  private _filterCiudades(value: string): CiudadModel[] {
    const filterValue = value.toLowerCase();
    return this.ListaCiudad.filter(ciudad => ciudad.nombreCiudad.toLowerCase().indexOf(filterValue) === 0);
  }

  backToHome() {
    this.router.navigate(['home'])
  }
}