import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';

import { throwError } from 'rxjs';
import { AppConfig } from '../appConfig';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    static settings: AppConfig ={
        env: "http://localhost:9080/desmontespublico/",
        dominio:"http://localhost:9080/",
        defaultLanguage: "",
        language: "",
        tenant: ""
    }
    
    constructor(private http: HttpClient) { }

    loadConfig(): Promise<AppConfig> {
        const jsonFile = `assets/config.tenant.json`;
        
        return this.http.get<AppConfig>(jsonFile).pipe(
            tap(
                resp => {
                    ConfigService.settings = resp
                    localStorage.setItem('currentTenant', ConfigService.settings.tenant);
                }),
            catchError(e => throwError(`Could not load file '${jsonFile}': ${JSON.stringify(e)}`))
        ).toPromise()
    }

}
