import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { GlobalService } from "src/app/services/global.service";
import { DireccionModel } from "src/app/model/denuncia/direccion";
import Swal from 'sweetalert2';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'cep-popup',
  templateUrl: 'cep-popup.html',
  providers: [
    GlobalService
  ]

})
export class cepPopUpComponent implements OnInit {
  public selectedCep: any = '';
  public selectedBarrio: any = '';
  public selectedDireccion: any = '';
  public selectedComplemento: any = '';
  public selectedDepartamento: any = '';
  public selectedCiudad: any = '';
  public abreviatura : string = '';
  public direccion = {} as DireccionModel;
  public isValid = true;
  public inputsDireccionEnabled = true;

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<cepPopUpComponent>,
    private _globalService: GlobalService) { }

  ngOnInit() {

  }

  onClick(): void {
    let json = {
      estado: this.selectedDepartamento,
      cidade: this.selectedCiudad,
      direccion: this.selectedDireccion,
      complemento: this.selectedComplemento,
      cep: this.selectedCep,
      bairro: this.selectedBarrio,
      abreviatura: this.abreviatura
    }
    this.direccion.complemento = this.selectedComplemento;
    this.dialogRef.close(json);
  }

  findDireccion(cep: string) {
    cep = cep.replace('-', '');
    this._globalService.findValorParametroById(393).then((parametroEstadoDefault: any) => {
      this._globalService.findValorParametroById(2763).then((data: any) => {
        if (data.valorParametros === "S") {
          this._globalService.findDireccionByApiCorreios(cep).then(
            (response: any) => {
              if(response.logradouro === null){
                this.inputsDireccionEnabled = false
              }else{
                this.inputsDireccionEnabled = true
              }
              this.isValid = false;
              this.selectedCep = response.cep
              this.selectedDireccion = response.logradouro;
              this.selectedBarrio = response.bairro
              this.selectedComplemento = response.complemento
              this.selectedDepartamento = response.localidadeSuperior
              this.selectedCiudad = response.localidade
              this.abreviatura = response.uf
            }).catch(err => {                
              console.log(err);      
              this.isValid = false;
            })
        } else {        
          this._globalService.findDireccion(cep).then(
            (response : any) => {
              console.log(response.length)
              if(response.length === 0){
                this.translate.get('searchAdress.title').subscribe((title: string) => {
                  this.translate.get('searchAdress.zipCodeNotFound').subscribe((message: string) => {
                    Swal.fire(title, message, "warning");
                  });
                });
                return
              }
              if(response[0].idDepartamento !== parametroEstadoDefault.valorParametros){
                this.translate.get('searchAdress.title').subscribe((title: string) => {
                  this.translate.get('searchAdress.zipCodeOutOfState').subscribe((message: string) => {
                    Swal.fire(title, message, "error")
                  })
                })
              } else {              
                this.isValid = false;
                if (response.length > 0) {
                  this.direccion = response[0];
                  this.selectedBarrio = this.direccion.barrio;
                  this.selectedDireccion = this.direccion.direccion;
                  this.abreviatura = this.direccion.abreviatura
                } else {
                  this.selectedBarrio = "";
                  this.selectedDireccion = "";
                }
              }
            },
            error => {
              console.log(error);
              this.isValid = false;
            }
          )
        }
      })
    })
  }
}