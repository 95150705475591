import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  PARAMETRO_CREDENCIAMENTO_EMPRESA_PDF = 3611;
  PARAMETRO_REGULAMENTO_PIEZA_PDF = 3612;
  PARAMETRO_CARTILHA_CREDENCIAMENTO_PDF = 3613; 
  
  urlCredenciamento: string;
  urlRegulamento: string;
  urlCartilha: string;

  constructor(private _globalService: GlobalService) { }

  ngOnInit() {
    this._globalService.findValorParametroById(this.PARAMETRO_CREDENCIAMENTO_EMPRESA_PDF).then((data: any) => {      
      if(data != null){
        this.urlCredenciamento = data.valorParametros;
      }      
    })
    this._globalService.findValorParametroById(this.PARAMETRO_REGULAMENTO_PIEZA_PDF).then((data: any) => {
      if(data != null){
        this.urlRegulamento = data.valorParametros;
      }
    })
    this._globalService.findValorParametroById(this.PARAMETRO_CARTILHA_CREDENCIAMENTO_PDF).then((data: any) => {
      if(data != null){
        this.urlCartilha = data.valorParametros;
      }
    })
  }

}
