<!-- Navbar -->
<nav class="navbar navbar-expand-md py-1">

    <div class="container d-flex justify-content-between">
      <div class="navbar-logo">
        <a class="navbar-logo__link d-flex" href="#">
          <img src="{{'./assets/themes/'+ _config.settings.tenant + '/images/logo-detran.png'}}" alt="" />
        </a>
      </div>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon p-1"><i class="fas fa-bars"></i> </span>
      </button>
      
      <div class="collapse navbar-collapse " id="navbarCollapse">
        <ul class="navbar-nav ">
          <li class="nav-item active">
            <a (click)="goToSearchPart()" class="nav-link" href="#">{{ 'menu.home' | translate }} </a>
          </li>
          <li class="nav-item">
            <a (click)="goToCompany()" class="nav-link" href="#">{{ 'menu.registeredCompanies' | translate }}</a>
          </li>
          <li class="nav-item">
            <a (click)="goToVeriFyPart()" class="nav-link" href="#">{{ 'menu.checkPiece' | translate }}</a>
          </li>
          <li class="nav-item">
            <a (click)="goToComplait()" class="nav-link" href="#">{{ 'menu.denounce' | translate }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="btn-group dropdown-language">
      <button type="button" class="btn dropdown-toggle dropdown-button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="fs-12">{{ 'menu.lenguage' | translate }}</span>
      </button>
        <div class="dropdown-menu" >
          <a class="dropdown-item" (click)="useLanguage('pt')">Português</a>
          <a class="dropdown-item" (click)="useLanguage('en')">English</a>
          <a class="dropdown-item" (click)="useLanguage('es')">Español</a>       
        </div>
    </div>
</nav>