import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PagesComponent } from './pages.component';
import { BuscarPiezaComponent } from './pieza/buscar-pieza/buscar-pieza.component';
import { VerificarPiezaComponent } from './pieza/verificar-pieza/verificar-pieza.component';
import { RegistrarDenunciaComponent } from './denuncia/registrar-denuncia/registrar-denuncia.component';
import { BuscarDenunciaComponent } from './denuncia/buscar-denuncia/buscar-denuncia.component';
import { BuscarEmpresaComponent } from './empresa/buscar-empresa/buscar-empresa.component';

const pagesRoutes: Routes = [
    {
        path: '',
        component: PagesComponent,
        children: [
            { path: 'home', component: HomeComponent },
            { path: 'registrar-denuncia', component: RegistrarDenunciaComponent },
            { path: 'pesquisar-denuncia', component: BuscarDenunciaComponent },
            { path: 'pesquisar-peca', component: BuscarPiezaComponent },
            { path: 'pesquisar-empresa', component: BuscarEmpresaComponent },
            { path: 'verificar-peca', component: VerificarPiezaComponent },
            { path: 'verificar-peca/:id', component: VerificarPiezaComponent },
            { path: '', redirectTo: '/home', pathMatch: 'full' },
        ]
    }

];

export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes);
