import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { map }  from 'rxjs/operators'
import { ConfigService } from 'src/app/services/config.service';
import { GLOBAL } from '../../../shared/parametrosGlobales';

@Injectable()
export class ContactoService {

  public url: string = ConfigService.settings.env;
  public path: string;

  constructor(public _http: Http) { }


  public saveContacto(contacto) {
    this.path = GLOBAL.contacto.saveContacto;
    return this._http.post(this.url + this.path, contacto).pipe(map(res => res.json()));
  }
}