<form name="form" (ngSubmit)="f.form.valid && verifyPiece($event)" #f="ngForm">
  <!--<re-captcha #captchaRef="reCaptcha" (resolved)="verifyPiece($event)"></re-captcha>-->
  <div class="row ">
    <div class="col-10 col-md-10 mb-3 companySearch">
        <div class="col-md-12 col-10">
          <mat-form-field class="example-full-width" style="width:100%">
            <input matInput type="text" placeholder="{{ 'search.inputs.verify' | translate }}"
              [(ngModel)]="codigoPieza" name="verify" #codeItem
              [ngClass]="{ 'is-invalid': f.submitted && codeItem.invalid }" required maxlength="15">
              <mat-error *ngIf="codeItem.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
          </mat-form-field>
        </div>
    </div>
    <div class="col-1 col-md-1 mb-3">
      <button class="btn btn-primary" type="submit" id="button-search">{{'common_inputs.search' | translate }}</button>
    </div>
  </div>
</form>