<div class="slide">
  <div class="slide-acordion container">

    <!-- Acordion-->
    <div class="">
      <div class="search">

        <!-- TAb 1-->

        <div class="search__title gray" [ngClass]="{'active': step===1, 'after-active': step < 1}" (click)="step=1">
          <span>{{ 'search.title.parts' | translate }} </span>
        </div>
        <div *ngIf="step===1" class="search__content gray">
          <h2>{{ 'search.title.parts' | translate }}</h2>
          <h3 class="text-left font-weight-normal">{{ 'search.message.part' | translate }}</h3>

          <div class=" mt-2 d-flex justify-content-center">
            <div class="col-md-11">

              <app-buscar-pieza-acordeon></app-buscar-pieza-acordeon>

            </div>
          </div>
          
        </div>

        <!-- / tab 2-->
        <div class="search__title blue" [ngClass]="{'active': step===2, 'after-active': step < 2}" (click)="step=2">
          <span>{{ 'search.title.company' | translate }}</span>

        </div>
        <div *ngIf="step===2" class="search__content blue">
          <h2>{{ 'search.title.company' | translate }}</h2>
          <h3 class="text-left font-weight-normal">{{ 'search.message.company' | translate }}</h3>
          <div class=" mt-2 d-flex justify-content-center">
            <div class="col-md-11">

              <app-empresa-acordeon></app-empresa-acordeon>

            </div>
          </div>
        </div>

        <!-- / tab 3-->
        <div class="search__title green" [ngClass]="{'active': step===3, 'after-active': step < 3}" (click)="step=3">
          <span>{{ 'search.title.verify' | translate }}</span>
        </div>
        <div *ngIf="step===3" class="search__content green">
          <h2>{{ 'search.title.verify' | translate }}</h2>
          <h3 class="text-left font-weight-normal">{{ 'search.message.verify' | translate }}</h3>
          <div class=" mt-5 d-flex justify-content-center">
            <div class="col-md-11">

              <app-verificar-acordeon></app-verificar-acordeon>

            </div>
          </div>
        </div>

        <!-- / tab 4-->
        <div class="search__title yellow" [ngClass]="{'active': step===4, 'after-active': step < 4}" (click)="step=4">
          <span>{{ 'search.title.complaint' | translate }} </span>
        </div>
        <div *ngIf="step===4" class="search__content yellow">
          <h2>{{ 'search.title.complaint' | translate }} </h2>
          <h3 class="text-left font-weight-normal">{{ 'search.message.complaint' | translate }}</h3>
          <div class=" mt-5 d-flex justify-content-center">
            <div class="col-md-11">

              <app-denuncia-acordeon></app-denuncia-acordeon>

            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

</div>

<!-- Banner -->
<app-banner></app-banner>
<!--  Fin Banner-->

<!-- Links -->
<app-link (step)=onChangeStep($event)></app-link>
<!-- Fin Links -->
