import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { map }  from 'rxjs/operators'
import { ConfigService } from 'src/app/services/config.service';
import { GLOBAL } from '../../../shared/parametrosGlobales';


@Injectable()
export class BuscarDenunciaService {

  public url: string = ConfigService.settings.env;
  public path: string;

  constructor(public _http: Http) { }


  public findDenunciaPorCodigoPin(criterioDeBusquedaDenuncia) {
    this.path = GLOBAL.denuncia.buscar.getDenunciasPorCodigoPin;
    return this._http.post(this.url + this.path, criterioDeBusquedaDenuncia).pipe(map(res => res.json()));
  }
}