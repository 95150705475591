<!-- Banner -->
<div class="banner-back">
  <div class="row">
    &nbsp;
  </div>
  <div class="row">
    <div class="container">
      <h3 style="color: #FFF;">{{ 'search.title.complaint' | translate }}</h3>
    </div>
  </div>
</div>

<!-- content -->
<div class="col-12 d-flex justify-content-center">
  <div class="row col-6 buscador shadow-lg justify-content-center">
    <form name="form" (ngSubmit)="f.form.valid && onClickNuevaBusquedad($event)" #f="ngForm">
      <!--<re-captcha #captchaRef="reCaptcha" (resolved)="onClickNuevaBusquedad($event)"></re-captcha>-->
      <div class="row d-flex justify-content-center pt-2">
        <div class="container">
          <h5>{{ 'resultComplaint.searchLabel' | translate }}</h5>
        </div>
      </div>

      <div class="row d-flex justify-content-center pt-2">
        <div class="col-md-10 col-10">
          <mat-form-field class="example-full-width ml-3">
            <input placeholder="{{ 'search.inputs.complaint.DenunciationCode' | translate }}" name="inputCodigoDenuncia"
              id="inputCodigoDenuncia" [(ngModel)]="criterioBusquedaDenuncia.codigoDenuncia" #codeNumber
              matInput required [ngClass]="{ 'is-invalid': f.submitted && codeNumber.invalid }">
            <mat-error *ngIf="codeNumber.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
          </mat-form-field>

        </div>

        <div class="col-md-10 col-10">
          <mat-form-field class="example-full-width ml-3">
            <input placeholder="{{ 'search.inputs.complaint.pinCode' | translate }}" name="inputPin" id="inputPin"
              [(ngModel)]="criterioBusquedaDenuncia.pin" #pinNumber matInput required
              [ngClass]="{ 'is-invalid': f.submitted && pinNumber.invalid }">
            <mat-error *ngIf="pinNumber.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-3 d-flex justify-content-center">
        <button type="submit" class="btn btn-primary btn-sm">
          {{ 'search.inputs.complaint.search' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<div class="pr-5 pl-5 pt-2 mt-5" *ngIf="resultadoDenuncia != null">
  <table class="table table-hover table-condensed">
    <thead>
      <tr class="color-fondo">
        <th class="text-center">{{ 'resultComplaint.tag.complainCode' | translate }}</th>
        <th class="text-center">{{ 'resultComplaint.tag.registerDate' | translate }}</th>
        <th class="text-center">{{ 'resultComplaint.tag.complaintStatus' | translate }}</th>
        <th class="text-center">{{ 'resultComplaint.tag.startVisitDate' | translate }}</th>
        <th class="text-center">{{ 'resultComplaint.tag.visitStatus' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr class="shadow">
        <td class="text-center">{{resultadoDenuncia.idSolicitudProcesoAdm}}</td>
        <td class="text-center">{{resultadoDenuncia.fechaRegistroSolicitud}}</td>
        <td class="text-center">{{resultadoDenuncia.estadoSolicitud}}</td>
        <td class="text-center">{{resultadoDenuncia.fechaVisitaInicio}}</td>
        <td class="text-center">{{resultadoDenuncia.estadoVisita}}</td>
      </tr>
    </tbody>
  </table>
</div>