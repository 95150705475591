import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

// Routes
import { PAGES_ROUTES } from './pages.routes';

import { PagesComponent } from './pages.component';
import { HomeComponent } from './home/home.component';

import { ComponentsModule } from '../components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VerificarPiezaComponent } from './pieza/verificar-pieza/verificar-pieza.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { BuscarPiezaComponent } from './pieza/buscar-pieza/buscar-pieza.component';
import { NgxSpinnerModule } from 'ngx-spinner';

// import recaptcha
import {
    RECAPTCHA_SETTINGS,
    RECAPTCHA_LANGUAGE,
    RecaptchaModule,
    RecaptchaSettings,
} from 'ng-recaptcha';
import { AppComponent } from '../app.component';
import { FileUploadModule  } from 'ng2-file-upload';
import { BrowserModule } from '@angular/platform-browser';
import { RegistrarDenunciaComponent } from './denuncia/registrar-denuncia/registrar-denuncia.component';
import { BuscarDenunciaComponent } from './denuncia/buscar-denuncia/buscar-denuncia.component';
import { BuscarEmpresaComponent } from './empresa/buscar-empresa/buscar-empresa.component';
import { cepPopUpComponent } from '../components/popup/cep/cepDialog.component';
import { SelectMultitenantComponent } from './select-multitenant/select-multitenant.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

const globalSettings: RecaptchaSettings = { siteKey: '6LfNcewnAAAAAMBDXEUzytD7i64QTgzBYioslOD0', size: 'invisible' };


@NgModule({
    declarations: [
        PagesComponent,
        HomeComponent,
        BuscarDenunciaComponent,
        VerificarPiezaComponent,
        RegistrarDenunciaComponent,
        BuscarEmpresaComponent,
        cepPopUpComponent,
        BuscarPiezaComponent,
        SelectMultitenantComponent

    ],
    bootstrap: [AppComponent],
    exports: [
        PagesComponent,
        HomeComponent,
        BuscarDenunciaComponent,
        VerificarPiezaComponent,
        RegistrarDenunciaComponent,
        BuscarEmpresaComponent,
        BuscarPiezaComponent
    ],
    imports: [
        PAGES_ROUTES,
        CommonModule,
        ComponentsModule,
        MatExpansionModule,
        FileUploadModule,
        MatInputModule,
        MatFormFieldModule,
        MatPaginatorModule,   
        MatTableModule,   
        MatOptionModule,  
        MatSelectModule,
        MatAutocompleteModule,
        MatIconModule,
        // configure the imports
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        FormsModule,
        ReactiveFormsModule,
        RecaptchaModule,
        NgxSpinnerModule,
        BrowserModule
    ],
    entryComponents: [
        cepPopUpComponent
    ],
    providers: [
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: globalSettings,
        },
        {
            provide: RECAPTCHA_LANGUAGE,
            useValue: 'pt-BR',
        }
    ]
})

export class PagesModule { }

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.txt');
}

