import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/shared/data.service';
import { ConfigService } from 'src/app/services/config.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  _config = ConfigService;

  constructor(private translate: TranslateService, private _dataNavBar: DataService,
  ) {
    translate.setDefaultLang('pt');
  }

  ngOnInit() {
  }

  goToSearchPart() {
    this._dataNavBar.cambiarNavBarData(1);
  }

  goToCompany() {
    this._dataNavBar.cambiarNavBarData(2);
  }

  goToVeriFyPart() {
    this._dataNavBar.cambiarNavBarData(3);
  }

  goToComplait() {
    this._dataNavBar.cambiarNavBarData(4);
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

}
