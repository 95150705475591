import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BuscarPiezaService } from 'src/app/pages/pieza/buscar-pieza/buscar-pieza.service';
import { RecaptchaService } from 'src/app/services/recaptcha.service';
import { DataService } from 'src/app/shared/data.service';
import Swal from 'sweetalert2';
import { PiezaModel } from 'src/app/model/pieza/pieza';
import { MarcaModel } from 'src/app/model/pieza/marca';
import { ModeloModel } from 'src/app/model/pieza/modelo';

export interface MarcaGroup {
  letter: string;
  names: MarcaModel[];
}

export interface ModeloGroup {
  letter: string;
  names: ModeloModel[];
}

export const _filterMarca = (opt: MarcaModel[], value: string): MarcaModel[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.nombreMarca.toLowerCase().indexOf(filterValue) === 0);
};

export const _filterModelo = (opt: ModeloModel[], value: string): ModeloModel[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.nombreReferencia.toLowerCase().indexOf(filterValue) === 0);
};

@Component({
  selector: 'app-buscar-pieza-acordeon',
  templateUrl: './buscar-pieza-acordeon.component.html',
  styleUrls: ['./buscar-pieza-acordeon.component.scss'],
  providers: [BuscarPiezaService, RecaptchaService]
})
export class BuscarPiezaAcordeonComponent implements OnInit {
  piezaCtrl = new FormControl();

  public listaTiposVehiculo = [];
  public listaPiezasMasBuscadas = [];
  public listaPiezas = [];
  public listaMarcasMasBuscadas = [];
  public listaModelosMasBuscados = [];
  public marcaMasBuscada: string;
  public modeloMasBuscado: string;
  public piezaMasBuscada: string;

  filteredPieza: Observable<PiezaModel[]>;

  marcaGroupOptions: Observable<MarcaGroup[]>;
  marcaCtrl = new FormControl();

  modeloGroupOptions: Observable<ModeloGroup[]>;
  modeloCtrl = new FormControl();

  public listaMarcas: MarcaModel[] = [];
  public listaModelos: ModeloModel[];

  selectedTipoVehiculo: string;
  selectedMarca: string;
  selectedModelo: string;
  selectedPieza: string;

  marcaGroups: MarcaGroup[] = [];
  modeloGroups: ModeloGroup[] = [];

  public idMarca: string = '';
  public idTipoVehiculo: string = '';
  public idModelo: string = '';

  public validation = true;
  BuscarPiezaAcordeonComponentForm: FormGroup;

  constructor(
    private router: Router,
    private _dataService: DataService,
    private _buscarPiezaService: BuscarPiezaService,
    private translate: TranslateService,
   private spinner: NgxSpinnerService,
    private _recaptchaService: RecaptchaService
  ) {
    this.marcaCtrl.disable();
    this.modeloCtrl.disable();
    this.piezaCtrl.disable();
    translate.setDefaultLang('pt');
  }
  @ViewChild("modelo") input: ElementRef;
  ngOnInit() {
    this.translate.setDefaultLang('pt');

    this.findTiposVehiculoYPiezas(this.idTipoVehiculo);
    this.findBranListAndlistaModelos(this.idMarca);

    this.translate.onLangChange.subscribe((data) => {
      this.translate.setDefaultLang(data.lang);
      this._dataService.idMarca.subscribe(idMarca => {
        this.findBranListAndlistaModelos(idMarca)
      });
      this._dataService.idTipoVehiculo.subscribe(idTipoVehiculo => {
        this.findTiposVehiculoYPiezas(idTipoVehiculo)
      });
    });

  }

  get f() { return this.BuscarPiezaAcordeonComponentForm.controls; }

  Validar() {
    this.validation = false;

    if (this.selectedMarca == null || this.selectedMarca == '') {
      this.getSwalError();
      this.spinner.hide();
    } else if (this.selectedModelo == null || this.selectedModelo == '') {
      this.getSwalError();
      this.spinner.hide();
    } else if (this.selectedPieza == null || this.selectedPieza == '') {
      this.getSwalError();
      this.spinner.hide();
    } else if (this.selectedTipoVehiculo == null || this.selectedTipoVehiculo == '') {
      this.getSwalError();
      this.spinner.hide();
    } else {
      this.validation = true;
    }
  }

  getSwalError() {
    this.translate.get('search.inputs.company.sweetAlert.empty.title').subscribe((title: string) => {
      this.translate.get('search.inputs.company.sweetAlert.empty.message').subscribe((message: string) => {
        Swal.fire(title, message, "warning");
      });
    });
  }

  findEmpresaPieza(captchaResponse: string) {
    this.spinner.show();
    this.Validar();
    if (this.validation) {
      this._recaptchaService.VerificarRespuesta(captchaResponse).then((
        response:any) => {
          if (response) {
            this.router.navigate(['pesquisar-peca'])
          }
          this.spinner.hide();
        },
        error => {
          console.log(error);
          this.spinner.hide();
        }
      );
    }
  }

  findTiposVehiculoYPiezas(idTipoVehiculo: string) {
    this.piezaMasBuscada = '';
    this._buscarPiezaService.findTipoVehiculo().subscribe(
      response => {
        this.listaTiposVehiculo = response;
        if (idTipoVehiculo != ''){
          this.spinner.hide();
        }
      },
      error => {
        this.spinner.hide();
        console.log(error);
      }
    )
    if (idTipoVehiculo != '' && this.idMarca && this.idModelo) {

      this._buscarPiezaService.findPiezaMasVendida(idTipoVehiculo, this.idMarca, this.idModelo).subscribe(
        response => {
          this.listaPiezasMasBuscadas = response;

          let i = 0;

          this.listaPiezasMasBuscadas.forEach(element => {
            i++;
            if (i == this.listaPiezasMasBuscadas.length) {
              this.piezaMasBuscada += element.idTipoPieza;
            } else {
              this.piezaMasBuscada += element.idTipoPieza + ", ";
            }
          });

          this.spinner.hide();

          this._buscarPiezaService.findPieza(idTipoVehiculo, this.piezaMasBuscada, this.idMarca, this.idModelo).subscribe(
            response => {
              this.listaPiezas = response;
              this.filteredPieza = null;
            },
            error => {
              console.log(error);
              this.spinner.hide();
            }
          )
        },
        error => {
          console.log(error);
          this.spinner.hide();
        }
      )
    }
  }

  findBranListAndlistaModelos(idMarca: string) {

    this.spinner.show();

    this.marcaMasBuscada = '';
    this.modeloMasBuscado = '';

    let mostWanted;
    this.translate.get('search.inputs.part.mostWanted').subscribe((value: string) => {
      mostWanted = value;
    });

    let otherBrands;
    this.translate.get('search.inputs.part.otherBrands').subscribe((value: string) => {
      otherBrands = value;
    });

    let otherModels
    this.translate.get('search.inputs.part.otherModels').subscribe((value: string) => {
      otherModels = value;
    });

    this._buscarPiezaService.findMarcaMasBuscada().subscribe(
      response => {
        this.listaMarcasMasBuscadas = response;
        if (idMarca != ''){
          this.spinner.hide();
        }

        let i = 0;

        this.listaMarcasMasBuscadas.forEach(element => {
          i++;
          if (i == this.listaMarcasMasBuscadas.length) {
            this.marcaMasBuscada += element.idMarca;
          } else {
            this.marcaMasBuscada += element.idMarca + ", ";
          }
        });

        this._buscarPiezaService.findMarca(this.marcaMasBuscada).subscribe(
          response => {
            this.marcaGroups = [];

            this.listaMarcas = response;

            this.marcaGroups.push(
              {
                letter: mostWanted,
                names: this.listaMarcasMasBuscadas
              }
              ,
              {
                letter: otherBrands,
                names: this.listaMarcas
              }
            )
            this.marcaGroupOptions = this.marcaCtrl.valueChanges
              .pipe(
                startWith(''),
                map(value => this._filterMarcaGroup(value))
              );
              this.spinner.hide();
          },
          error => {
            console.log(error);
            this.spinner.hide();
          }
        );

      },
      error => {
        console.log(error);
        this.spinner.hide();
      }
    )
    this._buscarPiezaService.RefComercialMasBuscadas(idMarca).subscribe(
      response => {
        this.listaModelosMasBuscados = response;

        let i = 0;

        this.listaModelosMasBuscados.forEach(element => {
          i++;
          if (i == this.listaModelosMasBuscados.length) {
            this.modeloMasBuscado += element.idReferenciaComercial;
          } else {
            this.modeloMasBuscado += element.idReferenciaComercial + ", ";
          }
        });

        this._buscarPiezaService.findReferenciaComercial(idMarca, "0").subscribe(
          response => {
            this.modeloGroups = [];

            this.listaModelos = response;

            if (this.listaModelosMasBuscados.length > 0) {
              this.modeloGroups.push(
                {
                  letter: mostWanted,
                  names: this.listaModelosMasBuscados
                }
                ,
                {
                  letter: otherModels,
                  names: this.listaModelos
                }
              )
            } else {
              this.modeloGroups.push(
                {
                  letter: otherModels,
                  names: this.listaModelos
                }
              )
            }

            this.modeloGroupOptions = this.modeloCtrl.valueChanges
              .pipe(
                startWith(''),
                map(value => this._filterModeloGroup(value))
              );
              this.spinner.hide();
          },
          error => {
            console.log(error);
            this.spinner.hide();
          }
        );

      },
      error => {
        console.log(error);
        this.spinner.hide();
      }
    )
  }

  onChangePieza(value) {
    if (value == '') {
      this.filteredPieza = null;
    } else {
      this.filteredPieza = this.piezaCtrl.valueChanges
        .pipe(
          startWith(value),
          map(pieza => pieza ? this._filterPieza(pieza) : this.listaPiezas.slice())
        );

      this.filteredPieza.subscribe((x) => {
        if (x.length == 0) {
          this.filteredPieza = null;

        }
      })
    }
  }

  onSelectMarca(idMarca) {
    this.idMarca = idMarca;

    this.selectedModelo = "";
    this._dataService.cambiarBuscarPiezaData(null, idMarca, null, null);
    this.findBranListAndlistaModelos(idMarca);
    this.checkDisabledInput();
  }

  onSelectModelo(idModel) {
    this.idModelo = idModel;
    this._dataService.cambiarBuscarPiezaData(null, null, idModel, null);
    this.checkDisabledInput();

    if (this.idTipoVehiculo != '' && this.idMarca && this.idModelo) {

      this._buscarPiezaService.findPiezaMasVendida(this.idTipoVehiculo, this.idMarca, this.idModelo).subscribe(
        response => {
          this.listaPiezasMasBuscadas = response;

          let i = 0;

          this.listaPiezasMasBuscadas.forEach(element => {
            i++;
            if (i == this.listaPiezasMasBuscadas.length) {
              this.piezaMasBuscada += element.idTipoPieza;
            } else {
              this.piezaMasBuscada += element.idTipoPieza + ", ";
            }
          });

          this.spinner.hide();

          this._buscarPiezaService.findPieza(this.idTipoVehiculo, this.piezaMasBuscada, this.idMarca, this.idModelo).subscribe(
            response => {
              this.listaPiezas = response;
              this.filteredPieza = null;
            },
            error => {
              console.log(error);
              this.spinner.hide();
            }
          )
        },
        error => {
          console.log(error);
          this.spinner.hide();
        }
      )
    }
  }

  onSelectTipoVehiculo(idTipoVehiculo) {
    this.idTipoVehiculo = idTipoVehiculo;

    this._dataService.cambiarBuscarPiezaData(idTipoVehiculo, null, null, null);
    this.findTiposVehiculoYPiezas(idTipoVehiculo);
    this.checkDisabledInput();
  }

  onSelectPieza(idPieza) {
    if (idPieza == 0) {
      this.selectedPieza = '';
    }
    this._dataService.cambiarBuscarPiezaData(null, null, null, idPieza);
  }

  onClickPiezaMasBuscada(piezaMasBuscada) {
    this.input.nativeElement.focus()

    if (piezaMasBuscada.idTipoPieza == 0) {
      this.selectedPieza = '';
    }
    this._dataService.cambiarBuscarPiezaData(null, null, null, piezaMasBuscada.idTipoPieza);
    this.selectedPieza = piezaMasBuscada.nombrePieza;
    this.eventFire(document.getElementById('button-search'), 'click');

  }

  eventFire(el, etype) {
    console.log("evento del click");
    console.log(el.fireEvent);
    if (el.fireEvent) {
      el.fireEvent('on' + etype);
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  }

  private _filterModeloGroup(value: string): ModeloGroup[] {
    if (value) {
      return this.modeloGroups
        .map(group => ({ letter: group.letter, names: _filterModelo(group.names, value) }))
        .filter(group => group.names.length > 0);
    }

    return this.modeloGroups;
  }
  private _filterMarcaGroup(value: string): MarcaGroup[] {
    if (value) {
      return this.marcaGroups
        .map(group => ({ letter: group.letter, names: _filterMarca(group.names, value) }))
        .filter(group => group.names.length > 0);
    }

    return this.marcaGroups;
  }

  private _filterPieza(value: string): PiezaModel[] {
    let filterValue = value.toLowerCase();
    return this.listaPiezas.filter(pieza => pieza.nombrePieza.toLowerCase().indexOf(filterValue) === 0);
  }

  checkDisabledInput(){
    if(this.selectedTipoVehiculo != null){
      this.marcaCtrl.enable()
    }
    if(this.selectedMarca != null){
      this.modeloCtrl.enable()
    }
    if(this.selectedModelo != null){
      this.piezaCtrl.enable()
    }
  }
}





