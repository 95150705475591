<!-- ============================================================== -->
<!-- Main  -->
<!-- ============================================================== -->
<!-- Navbar -->
        <div [class]="_config.settings.tenant">
                <app-navbar></app-navbar>
                
                <!-- ============================================================== -->
                <!-- Page wrapper  -->
                <!-- ============================================================== -->
                <div class="content">    
                                        
                        
                        <!-- ============================================================== -->
                        <!-- Start Page Content -->
                        <!-- ============================================================== -->
                        
                                                        
                                <router-outlet></router-outlet>                            
                                
                        
                        <!-- ============================================================== -->
                        <!-- End PAge Content -->
                        <!-- ============================================================== -->           


                </div>

                <!-- ============================================================== -->
                        <!-- Start footer -->
                        <!-- ============================================================== -->
                        <app-footer></app-footer>
                <!-- ============================================================== -->
                <!-- End Footer -->
                <!-- ============================================================== -->
        </div>
        

