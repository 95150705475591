export var GLOBAL = {
    url: 'desmontespublico/',
    correios: {
        getDireccionByApi:"correios/getDireccionByApi?cep=",
        getDireccionByApiCorreios:"correios/getDireccionByApiCorreios?cep="        
    },
    contacto: {
        saveContacto: "contacto/saveContacto"
    },
    denuncia: {
        buscar: {
            getDenunciasPorCodigoPin: "denuncias/getDenunciasPorCodigoPin"
        },
        registrar: {
            saveDenuncia: "denuncias/saveDenuncia"
        }
    },
    empresa: {
        getEmpresaPorActividad: "Empresas/getEmpresaPorActividad"
    },
    pieza: {
        buscar: {
            getPiezaEmpresa: "piezas/getPiezaEmpresa"
        },
        verificar: {
            getInformacionPiezaPorCodigo: "piezas/getInformacionPiezaPorCodigo?codigo="
        }
    },
    global: {
        getDepartamentoBySigla:"global/getDepartamentosPorSigla?sigla=",
        getTiposVehiculo: "global/getTiposVehiculo",
        getMarcas: "global/getMarcas?marcaMasBuscada=",
        getReferenciaComercial: "global/getReferenciaComercial",
        getPiezaPorTipoVehiculo: "global/getPiezaPorTipoVehiculo",
        getMarcasMasBuscadas: "global/getMarcasMasBuscadas",
        getRefComercialMasBuscadas: "global/getRefComercialMasBuscadas?idMarca=",
        getPiezasMasBuscadas: "global/getPiezasMasBuscadas?idTipoVehiculo=",
        getTipoDocumento: "global/getTipoDocumento",
        getTipoEmpresa: "global/getTipoEmpresa",
        getDepartamentos: "global/getDepartamentos",
        getCiudadesPorDepartamento: "global/getCiudadesPorDepartamento?idDepartamento=",
        getDireccionPorCEP: "global/getDireccionPorCEP?cep=",
        getCiudadesConDepartamentos: "global/getCiudadesConDepartamentos",
        getValorParametroById: "global/getValorParametroById?idParametro="
    },
    recaptcha: {
        validRecaptcha: "recaptcha/validRecaptcha?responseCaptcha="
    }
}