import { style } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styles: []
})
export class PagesComponent implements OnInit {

  _config = ConfigService;

  ngOnInit() {
    let currentTenant = localStorage.getItem('currentTenant');

    var styleTenant = document.createElement('link');
    styleTenant.type = 'text/css';
    styleTenant.rel = "stylesheet";
    styleTenant.id = "currentTenantStylesheet";
    styleTenant.href = 'tenant-'+ currentTenant +'-colors.css';
    document.head.appendChild(styleTenant);

    this.setAppFavicon();
  }

  constructor(private translate: TranslateService) {
    translate.setDefaultLang('pt');
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  setAppFavicon(){
    document.getElementById('appFavicon').setAttribute('href', "./assets/themes/" + this._config.settings.tenant + "/images/favicon.ico" );
 }

}
