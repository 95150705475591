<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="small" color="#fff" type="ball-spin">
  <p style="font-size: 20px; color: white">{{ 'searchPart.spinner' | translate }}</p>
</ngx-spinner>

<form name="form" (ngSubmit)="f.form.valid  && findEmpresaPieza($event)" #f="ngForm">
  <!--<re-captcha #captchaRef="reCaptcha" (resolved)="findEmpresaPieza($event)"></re-captcha>-->

  <div class="row ">
    <div class="col-12 mb-3 companySearch">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 companySearch--option">
          <div class="form-group">
            <mat-form-field style="width:100%">
              <mat-select placeholder="{{ 'common_inputs.typeVehicle' | translate  }}"
                [(ngModel)]="selectedTipoVehiculo" id="tipoVehiculo" name="tipoVehiculo" #tipoVehiculo
                [ngClass]="{ 'is-invalid': f.submitted && tipoVehiculo.invalid }" required >
                <mat-option (click)="onSelectTipoVehiculo(tipoVehiculo.idTipoVehiculo)"
                  *ngFor="let tipoVehiculo of listaTiposVehiculo" [value]="tipoVehiculo.nombreTipo">
                  {{tipoVehiculo.nombreTipo}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="tipoVehiculo.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 companySearch--option">
          <mat-form-field class="example-full-width" style="width:100%">
            <input [formControl]="marcaCtrl" matInput type="text" placeholder="{{ 'search.inputs.part.brand' | translate }}" aria-label="Marca"
              [matAutocomplete]="autoMarca" [(ngModel)]="selectedMarca" name="marca" #marca
              [ngClass]="{ 'is-invalid': f.submitted && marca.invalid }" required>
            <mat-autocomplete #autoMarca="matAutocomplete">
              <mat-optgroup *ngFor="let group of marcaGroupOptions | async" [label]="group.letter">
                <mat-option *ngFor="let marcaCtrl of group.names" [value]="marcaCtrl.nombreMarca"
                  (click)="onSelectMarca(marcaCtrl.idMarca)">
                  <span>{{marcaCtrl.nombreMarca}}</span>
                </mat-option>
              </mat-optgroup>
            </mat-autocomplete>
            <mat-error *ngIf="marcaCtrl.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 companySearch--option">
          <mat-form-field class="example-full-width" style="width:100%">
            <input [formControl]="modeloCtrl" matInput type="text" placeholder="{{ 'search.inputs.part.model' | translate }}" aria-label="Modelo"
              [matAutocomplete]="autoModelo" [(ngModel)]="selectedModelo" name="modelo" #modelo
              aria-expanded=false [ngClass]="{ 'is-invalid': f.submitted && modelo.invalid }" required>
            <mat-autocomplete #autoModelo="matAutocomplete">
              <mat-optgroup *ngFor="let group of modeloGroupOptions | async" [label]="group.letter">
                <mat-option *ngFor="let modeloCtrl of group.names" [value]="modeloCtrl.nombreReferencia"
                  (click)="onSelectModelo(modeloCtrl.idReferenciaComercial)">
                  <span>{{modeloCtrl.nombreReferencia}}</span>
                </mat-option>
              </mat-optgroup>
            </mat-autocomplete>
            <mat-error *ngIf="modeloCtrl.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-sm-6-col-md-3 col-lg-3">
          <mat-form-field class="example-full-width">
            <input [formControl]="piezaCtrl" (input)="onChangePieza($event.target.value)" matInput type="text"
              placeholder="{{ 'search.inputs.part.part' | translate }}" aria-label="Pieza" #pieza
              [matAutocomplete]="autoPieza" name="pieza" data-toggle="collapse" data-target="#collapseResults"
              aria-controls="collapseResults" [(ngModel)]="selectedPieza"
              [ngClass]="{ 'is-invalid': f.submitted && pieza.invalid }" required>
            <mat-autocomplete #autoPieza="matAutocomplete">
              <mat-option (click)="onSelectPieza(pieza.idTipoPieza)" *ngFor="let pieza of filteredPieza | async"
                [value]="pieza.nombrePieza">
                <span>{{pieza.nombrePieza}}</span>
              </mat-option>
              <mat-option *ngIf="filteredPieza == null">
                <span>{{ 'search.inputs.part.criteria' | translate }}</span>
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="pieza.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="listaPiezasMasBuscadas.length > 0 " class="collapse mt-3 mb-3" id="collapseResults">
        <div class="card">
          <div class="suggest__results justify-content-center">
            <span class="text-success fs-12">{{ 'search.button.mostWanted' | translate }}</span>
            <span *ngFor="let piezaMasBuscada of listaPiezasMasBuscadas">
              <button type="button" class="btn btn-success btn-sm ml-1 mb-1"
                (click)='onClickPiezaMasBuscada(piezaMasBuscada)' id="btnPrueba">{{piezaMasBuscada.nombrePieza}}
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-3 pl-0">
      <button (click)="f.form.valid" class="btn btn-primary d-none d-md-block" type="submit" id="button-search">
        {{'common_inputs.search' | translate }}
      </button>     
    </div>
  </div>

</form>