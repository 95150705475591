import { Component } from "@angular/core";
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from '@ngx-translate/core';
import { RecaptchaService } from 'src/app/services/recaptcha.service';
import { ContactoService } from 'src/app/components/popup/contacto/contacto-popup.service';
import { ContactoModel } from "../../../model/contacto/contacto";
import { ResultadoContactoModel } from "../../../model/contacto/resultadoContacto";
import { isNullOrUndefined } from "util";
import Swal from 'sweetalert2';


@Component({
  selector: 'contacto-popup',
  templateUrl: 'contacto-popup.html',
  providers: [ContactoService, RecaptchaService]
})
export class DialogOverview {

  contacto: ContactoModel;
  resultadoContacto: ResultadoContactoModel;

  email = new FormControl('', [Validators.email]);
  getErrorMessage() {
    return this.email.hasError('email') ? 'commonErrors.invalidEmail' :
      null;
  }

  constructor(
    public dialogRef: MatDialogRef<DialogOverview>,
    private translate: TranslateService,
    private _recaptchaService: RecaptchaService,
    private _saveContactoService: ContactoService) {
    this.contacto = new ContactoModel(null, null, null, null);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  enviarContactenos() {
    console.log(this.contacto);
    this._saveContactoService.saveContacto(this.contacto).subscribe(
      response => {
        this.dialogRef.close();
        if (response != null) {
          this.resultadoContacto = response;
          if (this.resultadoContacto.codigoRespuesta == 1) {
            this.translate.get('contactUs.ServiceSuccess.title').subscribe((title: string) => {
              this.translate.get('contactUs.ServiceSuccess.message').subscribe((message: string) => {
                Swal.fire(title, message, "success");
              });
            });
          } else {
            this.translate.get('contactUs.ServiceWarning.title').subscribe((title: string) => {
              this.translate.get('contactUs.ServiceWarning.message').subscribe((message: string) => {
                Swal.fire(title, message, "warning");
              });
            });
          }
        } else {
          this.translate.get('contactUs.ServiceError.title').subscribe((title: string) => {
            this.translate.get('contactUs.ServiceError.message').subscribe((message: string) => {
              Swal.fire(title, message, "error");
            });
          });
        }
      },
      error => {
        console.log(error);
      });
  }

  onClickNuevoContacto(captcha: string) {
    if (isNullOrUndefined(this.contacto.nombre.trim) || isNullOrUndefined(this.contacto.asunto.trim)
      || isNullOrUndefined(this.contacto.comentarios.trim) || !isNullOrUndefined(this.getErrorMessage())) {
      this.translate.get('common_inputs.sweetAlert.empty.title').subscribe((title: string) => {
        this.translate.get('common_inputs.sweetAlert.empty.message').subscribe((message: string) => {
          Swal.fire(title, message, "warning");
        });
      });
      grecaptcha.reset();
    } else {
      this._recaptchaService.VerificarRespuesta(captcha).then((
        response: any) => {
        if (response) {
          this.enviarContactenos();
        }
        grecaptcha.reset();
      },
        error => {
          console.log(error);
        }
      );
    }
  }
}