import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {

  //Company data
  public idActivity = new BehaviorSubject('');
  public idState = new BehaviorSubject('');
  public idCity = new BehaviorSubject('');
  public CNPJ = new BehaviorSubject('');
  public todos = new BehaviorSubject(false);
  currentIdActivity = this.idActivity.asObservable();
  currentIdState = this.idState.asObservable();
  currentIdCity = this.idCity.asObservable();
  currentCNPJ = this.CNPJ.asObservable();
  currenteTodos = this.todos.asObservable();

  // Verify Part data
  public codigoPieza = new BehaviorSubject('');
  currentcodigoPieza = this.codigoPieza.asObservable();

  // Complaint data
  public codigoDenuncia = new BehaviorSubject('');
  public pin = new BehaviorSubject('');
  currentCodigoDenuncia = this.codigoDenuncia.asObservable();
  currentPin = this.pin.asObservable();

  //Search Part
  public idTipoVehiculo = new BehaviorSubject('');
  currentidTipoVehiculo = this.idTipoVehiculo.asObservable();
  public idMarca = new BehaviorSubject('');
  currentidMarca = this.idMarca.asObservable();
  public idModel = new BehaviorSubject('');
  currentIdModel = this.idMarca.asObservable();
  public idPart = new BehaviorSubject('');
  currentIdPart = this.idPart.asObservable();

  //PRUEBA NAVBAR
  private dataNavBar = new BehaviorSubject(1);
  currentDataNavBar = this.dataNavBar.asObservable();

  constructor() { }

  cambiarNavBarData(dataNavBar) {
    this.dataNavBar.next(dataNavBar);
  }

  cambiarDenunciaData(codigoDenuncia: string, pin: string) {
    this.codigoDenuncia.next(codigoDenuncia);
    this.pin.next(pin);
  }

  cambiarBuscarEmpresaData(idActivity: string, idState: string, idCity: string, CNPJ: string, todos?: boolean) {
    if (idActivity != null) {
      this.idActivity.next(idActivity);
    }
    if (idState != null) {
      this.idState.next(idState);
    }
    if (idCity != null) {
      this.idCity.next(idCity);
    }
    if (CNPJ != null) {
      this.CNPJ.next(CNPJ);
    }
    if (todos != null) {
      this.todos.next(todos);
    }

  }

  cambiarVerificarPiezaData(codigoPieza: string) {
    if (codigoPieza != null) {
      this.codigoPieza.next(codigoPieza);
    }
  }

  cambiarBuscarPiezaData(idTipoVehiculo: string, idMarca: string, idModel: string, idPart: string) {
    if (idTipoVehiculo != null) {
      this.idTipoVehiculo.next(idTipoVehiculo);
    }
    if (idMarca != null) {
      this.idMarca.next(idMarca);
    }
    if (idModel != null) {
      this.idModel.next(idModel);
    }
    if (idPart != null) {
      this.idPart.next(idPart);
    }
  }


}