<!-- Banner -->
<div class="banner-back">
  <div class="row">
    &nbsp;
  </div>
  <div class="row">    
      <div class="container">
        <h3 style="color: #FFF;">{{ 'search.title.verify' | translate }}</h3>
      </div>
  </div>
</div>

<!-- content -->
<div class=" col-12 d-flex justify-content-center">
  <div class="col-6 buscador shadow-lg  ">
    <form name="form" (ngSubmit)="f.form.valid && onClickNuevaBusquedad($event)" #f="ngForm">
        <!--<re-captcha
            #captchaRef="reCaptcha"
            (resolved)="onClickNuevaBusquedad($event)"
        ></re-captcha>-->
      <div class="form-row">
        <h5 class="text-center">{{ 'search.message.verify' | translate }}</h5>
        <div class="col-md-10 col-10">
            <mat-form-field class="example-full-width ml-3">
                <input type="text" placeholder="{{ 'verifyPart.input' | translate }}" name="codigoPieza"
                id="codigoPieza" [(ngModel)]="codigoPieza" #itemCode matInput required
                [ngClass]="{ 'is-invalid': f.submitted && itemCode.invalid }">
                <mat-error *ngIf="itemCode.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
            </mat-form-field> 
        </div>

        <div style="text-align: center" class="col-sm-2 mt-4">
          <button class="btn btn-white" type="submit" id="button-search"><em class="fas fa-search-plus"></em></button>
        </div>
      </div>
    </form>
  </div>
</div>

<div *ngIf="pieza != null" class="row justify-content-center">

  <div style="border-top-left-radius:10px;border-top-right-radius:10px;" class="row col-sm-10 verifyCard d-flex flex-row">
    <div class="col-sm-3 mt-4 etiqueta">
      <div class="etiqueta--color p-2">

        <div class="etiqueta--icono"> <em class="fas fa-screwdriver"></em> 
          <div style="text-align:center;" class="center fs-16"><strong>{{ 'verifyPart.tag.partName' | translate }}</strong></div>
        </div>
        <p class=" etiqueta--texto fs-14"> {{pieza.nombrePieza}} </p>
      </div>
    </div>

    <div class="col-sm-3 mt-4 etiqueta">
      <div class="etiqueta--color p-2">

        <div class="etiqueta--icono">
          <em *ngIf="pieza.tipoVehiculo == 'Motocicleta'" class="fas fa-motorcycle"></em>
          <em *ngIf="pieza.tipoVehiculo == 'Automóvel'" class="fas fa-car"></em>
          <em *ngIf="pieza.tipoVehiculo == 'Ônibus'" class="fas fa-bus"></em>
          <em *ngIf="pieza.tipoVehiculo == 'Caminhão'" class="fas fa-car"></em>
        </div>
        <div style="text-align:center;" class="center fs-16"><strong>{{ 'verifyPart.tag.vehicleType' | translate }}</strong>
        </div>
        <p class=" etiqueta--texto fs-14"> {{pieza.tipoVehiculo}} </p>
      </div>
    </div>

    <div class="col-sm-3 mt-4 etiqueta">
      <div class="etiqueta--color p-2">

        <div class="etiqueta--icono"> <em class="fas fa-info"></em> </div>
        <div style="text-align:center;" class="center fs-16"><strong>{{ 'verifyPart.tag.statusPart' | translate }}</strong>
        </div>
        <p class=" etiqueta--texto fs-14"> {{pieza.estadoPieza}} </p>
      </div>
    </div>

    <div class="col-sm-3 mt-4 etiqueta">
      <div class="etiqueta--color p-2">

        <div class="etiqueta--icono">
          <em *ngIf="pieza.tipoVehiculo == 'Motocicleta'" class="fas fa-motorcycle"></em>
          <em *ngIf="pieza.tipoVehiculo == 'Automóvel'" class="fas fa-car"></em>
          <em *ngIf="pieza.tipoVehiculo == 'Ônibus'" class="fas fa-bus"></em>
          <em *ngIf="pieza.tipoVehiculo == 'Caminhão'" class="fas fa-car"></em>
        </div>
        <div style="text-align:center;" class="center fs-16"><strong>{{ 'verifyPart.tag.vehicleBrand' | translate }}</strong>
        </div>
        <p class=" etiqueta--texto fs-14"> {{pieza.marcaVehiculo}} </p>
      </div>
    </div>
  </div>

  <div style="border-bottom-left-radius:10px;border-bottom-right-radius:10px;" class="row col-sm-10 verifyCard d-flex flex-row">
    <div class="col-sm-3 mt-4 etiqueta">
      <div class="etiqueta--color p-2">
        <div class="etiqueta--icono">
          <em *ngIf="pieza.tipoVehiculo == 'Motocicleta'" class="fas fa-motorcycle"></em>
          <em *ngIf="pieza.tipoVehiculo == 'Automóvel'" class="fas fa-car"></em>
          <em *ngIf="pieza.tipoVehiculo == 'Ônibus'" class="fas fa-bus"></em>
          <em *ngIf="pieza.tipoVehiculo == 'Caminhão'" class="fas fa-car"></em>
        </div>
        <div style="text-align:center;" class="center fs-16"><strong> {{ 'verifyPart.tag.vehicleModel' | translate }}</strong>
        </div>
        <p class=" etiqueta--texto fs-14"> {{pieza.modeloVehiculo}} </p>
      </div>
    </div>

    <div class="col-sm-3 mt-4 etiqueta">
      <div class="etiqueta--color p-2">

        <div class="etiqueta--icono"> <em class="fas fa-building"></em> </div>
        <div style="text-align:center;" class="center fs-16"><strong>{{ 'verifyPart.tag.companyName' | translate }}</strong>
        </div>
        <p class=" etiqueta--texto fs-14"> {{pieza.nombreEmpresa}} </p>
      </div>
    </div>

    <div class="col-sm-3 mt-4 etiqueta">
      <div class="etiqueta--color p-2">

        <div class="etiqueta--icono"> <em class="fas fa-building"></em> </div>
        <div style="text-align:center;" class="center fs-16"> <strong>{{ 'verifyPart.tag.activityCompany' | translate
            }}</strong> </div>
        <p class=" etiqueta--texto fs-14"> {{pieza.actividadCompania}} </p>
      </div>
    </div>

    <div class="col-sm-3 mt-4 etiqueta">
      <div class="etiqueta--color p-2">

        <div class="etiqueta--icono"> <em class="fas fa-calendar-alt"></em> </div>
        <div style="text-align:center;" class="center fs-16"> <strong> {{ 'verifyPart.tag.modelYear' | translate }}
          </strong> </div>
        <p class=" etiqueta--texto fs-14"> {{pieza.modeloAnio}} </p>
      </div>
    </div>
  </div>
</div>