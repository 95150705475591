import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EmpresaModel } from 'src/app/model/empresa/empresa';
import { BuscarEmpresaService } from 'src/app/pages/empresa/buscar-empresa/buscar-empresa.service';
import { DataService } from 'src/app/shared/data.service';
import * as XLSX from 'xlsx';
import { CriteroBusquedaEmpresaModel } from 'src/app/model/empresa/criterioBusquedaEmpresa';
import Swal from 'sweetalert2';
import { MatPaginator } from '@angular/material/paginator';
import { trigger, state, style, transition, animate } from '@angular/animations';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  description: string;
}

@Component({
  selector: 'app-buscar-empresa',
  templateUrl: './buscar-empresa.component.html',
  styleUrls: ['./buscar-empresa.component.scss'],
  providers: [BuscarEmpresaService],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BuscarEmpresaComponent implements OnInit,AfterViewInit  { 

  listEmpresas = [];
  
  columnsToDisplay = ['CNPJ', 'socialReason', 'city', 'neighborhood'];
  dataForExpand = ['activityCompany','direccionCompleta','barrio','state','city','email','telephone']
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: PeriodicElement | null;

  @ViewChild('TABLE') table: ElementRef;
  displayedColumns;
  hidenColumnsXLS;
  dataSource;

  criterioBusquedaEmpresa: CriteroBusquedaEmpresaModel

  constructor(private _route: ActivatedRoute,
    breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    private _buscarEmpresaService: BuscarEmpresaService,
    private router: Router,
    private _dataSearchcompany: DataService
  ) {
    translate.setDefaultLang('pt');
    breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = ['CNPJ', 'Razão Social', 'Cidade', 'Bairro'];
    });
    this.hidenColumnsXLS = [null,null,null,null,null,null,null,null,null,{"hidden": true}];
    this.criterioBusquedaEmpresa = new CriteroBusquedaEmpresaModel("", "", "", "", false);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {

    this._dataSearchcompany.idActivity.subscribe(actividad => {
      actividad != '' ? this.criterioBusquedaEmpresa.actividad = actividad : this.criterioBusquedaEmpresa.actividad = null
    });

    this._dataSearchcompany.idState.subscribe(idEstado => {
      idEstado != '' ? this.criterioBusquedaEmpresa.idEstado = idEstado : this.criterioBusquedaEmpresa.idEstado = null
    });

    this._dataSearchcompany.idCity.subscribe(idCiudad => {
      idCiudad != '' ? this.criterioBusquedaEmpresa.idCiudad = idCiudad : this.criterioBusquedaEmpresa.idCiudad = null
    });
    this._dataSearchcompany.CNPJ.subscribe(CNPJ => {
      CNPJ != '' ? this.criterioBusquedaEmpresa.cnpj = CNPJ : this.criterioBusquedaEmpresa.cnpj = null
    });
    this._dataSearchcompany.todos.subscribe(todos => {
      todos ? this.criterioBusquedaEmpresa.todos = todos : this.criterioBusquedaEmpresa.todos = false
    });

    if (this.criterioBusquedaEmpresa == null) {
      this.router.navigate(['home']);
      this._dataSearchcompany.cambiarNavBarData(2);
    } else {
      let ELEMENT_DATA: EmpresaModel[] = [];

      this._buscarEmpresaService.findListaEmpresa(this.criterioBusquedaEmpresa).subscribe(
        response => {
          if (response.length > 0) {
            ELEMENT_DATA = this.gerarListaEmpresasPesquisadas(response);
            //ELEMENT_DATA = response;
            this.dataSource = new MatTableDataSource(ELEMENT_DATA);
            this.dataSource.paginator = this.paginator;
          } else {
            this.translate.get('searchCompany.sweetAlert.title').subscribe((title: string) => {
              this.translate.get('searchCompany.sweetAlert.message').subscribe((message: string) => {
                Swal.fire
                (title, message, "error").then((value) => {
                  this.router.navigate(['home']);
                  this._dataSearchcompany.cambiarNavBarData(2);
                });
              });
            });

          }

        },
        error => {
          console.log(error);
        }
      );
    }

    localStorage.clear();
  }

  gerarListaEmpresasPesquisadas(response:[]){
    let empresa = null;
    response.forEach((element:any)=>{
      empresa = null;
      empresa = {
        CNPJ:element.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1 $2 $3/$4-$5").replaceAll(" ","."),
        socialReason:element.razonSocial,
        city:element.nombreCiudad,
        neighborhood:element.barrio,
        direccionCompleta: element.direccionCompleta,
        activityCompany: element.tipoEmpresa,
        barrio:element.barrio,
        state:element.nombreDepartamento,
        email:element.email,
        telephone:(element.indicativo+element.telefono).replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1) $2').replace(/(\d{4})(\d)/, '$1-$2').replace(/(-\d{4})\d+?$/, '$1'),
        cep:element.cep.replace(/\D/g, '').replace(/(\d{5})(\d)/, '$1-$2').replace(/(-\d{3})\d+?$/, '$1'),
        numeroVia:element.numeroVia
      }
      this.listEmpresas.push(empresa)
    })  
    return this.listEmpresas;
  }

  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    ws['!cols'] = this.hidenColumnsXLS;
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, 'SheetJS.xlsx');

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }
}
