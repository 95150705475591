<form name="form" (ngSubmit)="f.form.valid && findCompany($event)" #f="ngForm">
  <!--<re-captcha #captchaRef="reCaptcha" (resolved)="findCompany($event)"></re-captcha>-->
  
  <div class="row companySearch">
    <div class="col-12 col-md-6 col-lg-3">
      <div class="">
        <div class="form-group">
          <mat-form-field style="width:100%">
            <mat-select (selectionChange)="onSelectCriteria()"
              placeholder="{{ 'search.inputs.company.criteria' | translate }}" [(ngModel)]="selectedCriteria"
              name="criteria" #criterio="ngModel" required>
              <mat-option>--</mat-option>
              <mat-option *ngFor="let criterio of criteria" [value]="criterio.value" (click)="setCriterio(criterio)">
                {{criterio.viewValue}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="criterio.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-3" *ngIf="selectedCriteria != '1' && criteriaSelecionado == undefined">
      <mat-form-field style="width:100%">
        <mat-select placeholder="{{ 'common_inputs.activity' | translate }}" [(ngModel)]="selectedTipoEmpresa"
          name="tipo" #tipo required>
          <mat-option (click)='onSelectTipoEmpresa(tipo)' *ngFor="let tipo of listaTiposEmpresa"
            [value]="tipo.nombreEmpresa">
            {{tipo.nombreEmpresa}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="tipo.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
      </mat-form-field>
    </div>

    <div style="display:none" class="col-12 col-md-6 col-lg-3" *ngIf="selectedCriteria != '1'">
        <mat-form-field class="example-full-width">
          <input [formControl]="departamentoCtrl" matInput type="text" placeholder="{{ 'common_inputs.state' | translate }}" aria-label="Departamento"
            [matAutocomplete]="autoDepartamento" [(ngModel)]="selectedDepartamento" name="departamento" #departamento required>
          <mat-autocomplete #autoDepartamento="matAutocomplete">
            <mat-option (click)='onSelectDepartamento(departamentoCtrl)' *ngFor="let departamentoCtrl of filteredDepartamento | async"
              [value]="departamentoCtrl.nombreDepartamento">
              <span>{{departamentoCtrl.nombreDepartamento}}</span>
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="departamentoCtrl.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
        </mat-form-field>
    </div>

    <div class="col-12 col-md-6 col-lg-3" *ngIf="selectedCriteria != '1' && disabled === false && criteriaSelecionado == undefined">
      <mat-form-field class="example-full-width">
        <input [formControl]="ciudadCtrl" [attr.disabled]="disabled?true:null" matInput placeholder="{{ 'common_inputs.city' | translate }}"
          aria-label="Ciudad" [matAutocomplete]="autoCiudad" [(ngModel)]="selectedCiudad" name="ciudad"
          #ciudad required>
        <mat-autocomplete #autoCiudad="matAutocomplete">
          <mat-option (click)='onSelectCiudad(ciudadCtrl)' *ngFor="let ciudadCtrl of filteredCiudad | async"
            [value]="ciudadCtrl.nombreCiudad">
            <span>{{ciudadCtrl.nombreCiudad}}</span>
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="ciudadCtrl.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
      </mat-form-field>
    </div>

    <div class="col-12 col-md-6 col-lg-3" *ngIf="selectedCriteria == '1'">
      <mat-form-field class="example-full-width">
        <input type="text" placeholder="{{ 'common_inputs.CNPJ' | translate }}" [(ngModel)]="selectedCNPJ" name="cnpj" #cnpj="ngModel" matInput required>
        <mat-error *ngIf="cnpj.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
      </mat-form-field>
    </div>

    <div class="col-12" *ngIf="selectedCriteria != '1' && criteriaSelecionado == undefined">
      <mat-checkbox (change)="onChangeCheckbox()" class="example-margin" [(ngModel)]="disabled" name="disabled">{{'common_inputs.allCompanies' | translate }}</mat-checkbox>
    </div>

  </div>

  <div class="row mt-3">
    <div class="col-12 col-md-3 col-lg-auto">
      <button class="btn btn-primary w-100" type="submit">{{'common_inputs.search' | translate }}</button>
    </div>
  </div>


</form>