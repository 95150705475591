import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { GLOBAL } from '../shared/parametrosGlobales';
import { ConfigService } from './config.service';

@Injectable()
export class RecaptchaService {

    public url: string =  ConfigService.settings.env;
    public path: string;

    constructor(public _http: Http) { }

    public VerificarRespuesta(captchaResponse: string) {
       this.path = GLOBAL.recaptcha.validRecaptcha + captchaResponse;
       return new Promise((resolve)=>{ resolve(true)});
    }

}