import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RecaptchaService } from 'src/app/services/recaptcha.service';
import { DataService } from 'src/app/shared/data.service';
import { FormGroup } from '@angular/forms';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-verificar-acordeon',
  templateUrl: './verificar-pieza-acordeon.component.html',
  styleUrls: ['./verificar-pieza-acordeon.component.scss'],
  providers: [RecaptchaService]
})
export class VerificarPiezaAcordeonComponent implements OnInit {
  verificarForm: FormGroup;
  codigoPieza: string;
  constructor(private router: Router,
    private translate: TranslateService,
    private _dataVerificarPieza: DataService,
    private _recaptchaService: RecaptchaService

  ) { }

  ngOnInit() {
    console.log(ConfigService.settings.env);
  }

  get f() { return this.verificarForm.controls; }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  verifyPiece(captchaResponse: string) {
    this._recaptchaService.VerificarRespuesta(captchaResponse).then((
      response:any) => {
        if (response) {
          this._dataVerificarPieza.cambiarVerificarPiezaData(this.codigoPieza);
          this.router.navigate(['verificar-peca'])
        }
        grecaptcha.reset();
      },
      error => {
        console.log(error);
      }
    );
  }

}
