import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/data.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('popOverState', [
      state('show', style({
        opacity: 1,
        width: '600px'
      })),
      state('hide', style({
        opacity: 0,
        width: '45px'
      })),
      transition('show => hide', animate('600ms ease-out')),
      transition('hide => show', animate('1000ms ease-in'))
    ]),
    trigger('popOverState2', [
      state('show2', style({
        opacity: 1,
        width: '600px'
      })),
      state('hide2', style({
        opacity: 0,
        width: '45px'
      })),
      transition('show2 => hide2', animate('600ms ease-out')),
      transition('hide2 => show2', animate('1000ms ease-in'))
    ])
  ]
})
export class HomeComponent implements OnInit {

  step: number = 1;

  show = true;
  show2 = false;

  title = 'desmontes-Brasil';

  constructor( private _dataNavBar: DataService) {
    _dataNavBar.currentDataNavBar.subscribe(data => {
      this.step = data
    })
   }

  get stateName() {
    return this.show ? 'show' : 'hide'
  }
  get stateName2() {
    return this.show2 ? 'show2' : 'hide2'
  }

  onChangeStep(value: string) {
    this.step = Number(value);
  }

  toggle() {
    this.show = !this.show;
  }
  toggle2() {
    this.show2 = !this.show2;
  }

  ngOnInit() {
  }

}
