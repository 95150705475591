import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import 'hammerjs';
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MaterialModule } from '../material';
import { BuscarPiezaAcordeonComponent } from './acordeon/buscar-pieza/buscar-pieza-acordeon.component';
import { VerificarPiezaAcordeonComponent } from './acordeon/verificar-pieza/verificar-pieza-acordeon.component';
import { EmpresaAcordeonComponent } from './acordeon/empresa/empresa-acordeon.component';
import { DenunciaAcordeonComponent } from './acordeon/denuncia/denuncia-acordeon.component';
import { ContactoBannerComponent } from './banner/contacto/contacto-banner.component';
import { InformacionBannerComponent } from './banner/informacion/informacion-banner.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { DataService } from '../shared/data.service';
import { DialogOverview } from './popup/contacto/contacto-popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatOptionModule } from '@angular/material/core';

const globalSettings: RecaptchaSettings = { siteKey: '6LfNcewnAAAAAMBDXEUzytD7i64QTgzBYioslOD0', size: 'invisible' };

@NgModule({
  declarations: [
    BuscarPiezaAcordeonComponent,
    VerificarPiezaAcordeonComponent,
    EmpresaAcordeonComponent,
    DenunciaAcordeonComponent,
    ContactoBannerComponent,
    DialogOverview,
    InformacionBannerComponent,
    FooterComponent,
    NavbarComponent
  ],
  exports: [
    BuscarPiezaAcordeonComponent,
    VerificarPiezaAcordeonComponent,
    EmpresaAcordeonComponent,
    DenunciaAcordeonComponent,
    ContactoBannerComponent,
    InformacionBannerComponent,
    FooterComponent,
    NavbarComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    MatOptionModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RecaptchaModule,
    NgxSpinnerModule
  ],
  entryComponents: [
    DialogOverview
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: globalSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'pt-BR',
    },
    DataService
  ]

})
export class ComponentsModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}