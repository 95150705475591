<div class="row">
  <div class="col-12 col-md-6">
    <button (click)="registerDenouncement()" type="button" class="btn btn-white btn-second w-100 mr-md-3">{{'search.inputs.complaint.register' | translate }}</button>
  </div>
  <div class="col-12 col-md-6 mt-3 mt-md-0">
    <button type="button" class="btn btn-white btn-first w-100 ml-md-3" data-toggle="collapse" data-target="#collapseContinuarDenuncias" aria-expanded="false">{{ 'search.inputs.complaint.consult' | translate }}</button>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="collapse mt-1" id="collapseContinuarDenuncias">
      <div class="offset-md-6 col-md-6 col-12 card card-body">
        <form name="form" (ngSubmit)="f.form.valid && buscarDenuncia($event)" #f="ngForm">
          <!--<re-captcha #captchaRef="reCaptcha" (resolved)="buscarDenuncia($event)"></re-captcha>-->
          <div class="col-md-10 col-10">
              <mat-form-field class="example-full-width ml-3">
                  <input placeholder="{{ 'search.inputs.complaint.DenunciationCode' | translate }}" name="inputCodigoDenuncia"
                  id="inputCodigoDenuncia" [(ngModel)]="codigoDenuncia" #codeNumber="ngModel" matInput required
                  [ngClass]="{ 'is-invalid': f.submitted && codeNumber.invalid }">
                  <mat-error *ngIf="codeNumber.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
              </mat-form-field>
          </div>
          <div class="col-md-10 col-10">
              <mat-form-field class="example-full-width ml-3">
                <input placeholder="{{ 'search.inputs.complaint.pinCode' | translate }}" name="inputPin" id="inputPin"
                  [(ngModel)]="pin" #pinNumber="ngModel" matInput required
                  [ngClass]="{ 'is-invalid': f.submitted && pinNumber.invalid }">
                  <mat-error *ngIf="pinNumber.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
              </mat-form-field>
            </div>

          <button type="submit" class="btn btn-primary btn-sm">{{ 'search.inputs.complaint.search' | translate }}</button>
        </form>
      </div>
    </div>
  </div>
</div>
