import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RecaptchaService } from 'src/app/services/recaptcha.service';

import { FormGroup } from '@angular/forms';
import { DataService } from '../../../shared/data.service';

@Component({
  selector: 'app-denuncia-acordeon',
  templateUrl: './denuncia-acordeon.component.html',
  styleUrls: ['./denuncia-acordeon.component.scss'],
  providers: [RecaptchaService]
})
export class DenunciaAcordeonComponent implements OnInit {
  denouncement: FormGroup;
  codigoDenuncia: string;
  pin: string;
  constructor(
    private router: Router, 
    private translate: TranslateService, 
    private data: DataService,
    private _recaptchaService: RecaptchaService) { }
    
  ngOnInit() {
  }

  get f() { return this.denouncement.controls; }
  registerDenouncement() {
    this.router.navigate(['registrar-denuncia'])
  }

  buscarDenuncia(captcha:string) {
    this._recaptchaService.VerificarRespuesta(captcha).then((
      response) =>{
        if (response) {
          this.data.cambiarDenunciaData(this.codigoDenuncia, this.pin);
          this.router.navigate(['pesquisar-denuncia']);
        }
        grecaptcha.reset();
      },
      error => {
        console.log(error);
      }
    );
  }

}
