import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RecaptchaService } from 'src/app/services/recaptcha.service';
import { VerifyPartService } from 'src/app/pages/pieza/verificar-pieza/verificar-pieza.service';
import { DataService } from 'src/app/shared/data.service';
import Swal from 'sweetalert2';
import { FormGroup } from '@angular/forms';
import { VerificarPiezaModel } from 'src/app/model/pieza/verificarPieza';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-verificar-pieza',
  templateUrl: './verificar-pieza.component.html',
  styleUrls: ['./verificar-pieza.component.scss'],
  providers: [VerifyPartService, RecaptchaService]
})
export class VerificarPiezaComponent implements OnInit {

  public pieza: VerificarPiezaModel;
  public codigoPieza: string;
  public nEtiqueta;
  verifyPart: FormGroup;
  constructor(
    private _verifyPartService: VerifyPartService,
    private translate: TranslateService,
    private _dataVerificarPieza: DataService,
    private _recaptchaService: RecaptchaService,
    private _route : ActivatedRoute

  ) {
    translate.setDefaultLang('pt');
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this.nEtiqueta = params.id;    
      this.codigoPieza = this.nEtiqueta;
      this.findPiezaPorCodigo();
    })
  }

  get f() { return this.verifyPart.controls; }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  onClickNuevaBusquedad(captchaResponse: string) {

    this._recaptchaService.VerificarRespuesta(captchaResponse).then((
      response :any)=> {
        if (response) {
          this._dataVerificarPieza.cambiarVerificarPiezaData(this.codigoPieza);
          this.findPiezaPorCodigo();
        }
        grecaptcha.reset();
      },
      error => {
        console.log(error);
      }
    );
  }

  findPiezaPorCodigo() {

    let code: any;

    this._dataVerificarPieza.codigoPieza.subscribe(codigoPieza => {
      codigoPieza != '' ? code = codigoPieza : code = null
    });
    if(this.nEtiqueta!=null){
      code=this.nEtiqueta;
    }

    if (code != null) {
      this._verifyPartService.findPiezaPorCodigo(code).subscribe(
        response => {

          this.pieza = response;

          this.translate.get('verifyPart.sweetAlert.title').subscribe((title: string) => {
            this.translate.get('verifyPart.sweetAlert.message').subscribe((message: string) => {
              if (this.pieza == null) {
                Swal.fire(title, message, "error");
              }
            });
          });

        },
        error => {
          console.log(error);
        }
      );
    }

  }

}
