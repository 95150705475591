<div class="container">
  <div class="row">
    <div class="col-12">
      <h5>{{ 'contactUs.message' | translate }}</h5>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <form name="form" (ngSubmit)="f.form.valid && onClickNuevoContacto($event)" #f="ngForm">
        
        <!--<re-captcha #captchaRef="reCaptcha" (resolved)="onClickNuevoContacto($event)"></re-captcha>-->

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <mat-form-field>
                  <input placeholder="{{ 'contactUs.contactFields.mailSubject' | translate }}" name="inputAsunto"
                  id="inputAsunto" [(ngModel)]="contacto.asunto" #contactSubject matInput required
                  [ngClass]="{ 'is-invalid': f.submitted && contactSubject.invalid }">
                  <mat-error *ngIf="contactSubject.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <mat-form-field>
                  <input placeholder="{{ 'contactUs.contactFields.name' | translate }}" name="inputNombre"
                  id="inputNombre" [(ngModel)]="contacto.nombre" #contactName matInput required
                  [ngClass]="{ 'is-invalid': f.submitted && contactName.invalid }">
                  <mat-error *ngIf="contactName.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
              </mat-form-field> 
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <mat-form-field>
                  <input matInput placeholder="{{ 'contactUs.contactFields.email' | translate }}" [formControl]="email"
                    name="email" id="email" [(ngModel)]="contacto.email">
                  <mat-error *ngIf="email.invalid">{{ getErrorMessage()  | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <mat-form-field>
                  <textarea placeholder="{{ 'contactUs.contactFields.mailBody' | translate }}" name="inputCuerpoCorreo"
                  id="inputCuerpoCorreo" [(ngModel)]="contacto.comentarios" #contactBodyMail matInput required
                  [ngClass]="{ 'is-invalid': f.submitted && contactBodyMail.invalid }"></textarea>
                  <mat-error *ngIf="contactBodyMail.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        
        <button (click)="onNoClick()" class="btn btn-default">{{ 'contactUs.buttons.back' | translate }}</button>
        <button type="submit" class="btn btn-primary ml-3"  cdkFocusInitial> {{ 'contactUs.buttons.submit' | translate }}</button>
      </form>
    </div>
  </div>
</div>



