import { Evidencias } from 'src/app/model/denuncia/evidencias';
export class DenunciaModel {
  constructor(
    public denunciante: string,
    public email: string,
    public ddd: number,
    public telefono: string,
    public cep: number,
    public idDepartamento: number,
    public nombreDepartamento: string,
    public barrio: string,
    public numero: string,
    public nombreCiudad: string,
    public idCiudad: number,
    public direccion: string,
    public complemento: string,
    public idTipoIdentificacion: number,
    public numeroIdentificacion: number,
    public idBarrio: number,
    public nombreEmpresa: string,
    public denuncia: string,
    public listaEvidencias: Evidencias[]
  ) { }
}