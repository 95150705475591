import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/services/config.service';
@Component({
  selector: 'app-link',
  templateUrl: './informacion-banner.component.html',
  styleUrls: ['./informacion-banner.component.scss']
})
export class InformacionBannerComponent implements OnInit {

  _config = ConfigService;

  constructor(private translate: TranslateService,
    private router: Router) {
    translate.setDefaultLang('pt');
  }

  ngOnInit() {
  }

  @Output() step = new EventEmitter<string>();


  goToVeriFyPart() {
    this.step.emit("3");
  }
  goToSearchPart() {
    this.step.emit("2");
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

}
