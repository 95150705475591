import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { APP_BASE_HREF, CommonModule } from '@angular/common';

/* Servicios */
import { ConfigService } from "./services/config.service";

//Rutas
import { APP_ROUTS } from './app-routing.module';

// Modulos
import { PagesModule } from './pages/pages.module';
import { ComponentsModule } from './components/components.module';

// Material
import { MaterialModule } from './material';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from '@angular/http';
import { Pagina404Component } from './pages/404/pagina-404.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GlobalService } from './services/global.service';


@NgModule({
  declarations: [
    AppComponent,
    Pagina404Component
  ],
  imports: [
    BrowserModule,
    CommonModule,
    APP_ROUTS,
    PagesModule,
    ComponentsModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ],
  providers: [
    GlobalService,
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}


export function initializeApp(appConfig: ConfigService) {
  return () => appConfig.loadConfig();
}


