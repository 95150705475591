<div class="container mt-3">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="mb-2 card-header bg-primary text-white font-weight-bold">
                    {{ 'complaint.addressData' | translate }}
                </div>
                
                <div class="p-3 form-row d-flex justify-content-center">
                    <div class="col-md-6 col-12">
                        <mat-form-field class="example-full-width">
                            <input type="text" placeholder="{{ 'common_inputs.CEP' | translate }}" aria-label="Number"
                                matInput [(ngModel)]="selectedCep" maxlength="9" (blur)="findDireccion(selectedCep)">
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-12">
                        <mat-form-field>
                            <input matInput placeholder="{{ 'common_inputs.neighborhood' | translate }}" [(ngModel)]="selectedBarrio" [disabled]="inputsDireccionEnabled"
                                >
                        </mat-form-field>
                    </div>
                </div>
                <div class="p-3 form-row d-flex justify-content-center">
                    <div class="col-md-6 col-12">
                        <mat-form-field>
                            <input matInput placeholder="{{ 'common_inputs.complement' | translate }}  {{ 'common_inputs.optional' | translate }}" [disabled]="inputsDireccionEnabled" [(ngModel)]="selectedComplemento">
                        </mat-form-field>
                    </div>

                    <div class="col-md-6 col-12">
                        <mat-form-field>
                            <input matInput placeholder="{{ 'common_inputs.address' | translate }}" [disabled]="inputsDireccionEnabled" [(ngModel)]="selectedDireccion"
                                >
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-flex justify-content-center">     
                    <button  [disabled]="isValid" (click)="onClick()" class="btn btn-primary" cdkFocusInitial>{{ 'common_inputs.accept' | translate }}</button>
                    <button type="submit" class="btn btn-default ml-3" (click)="this.dialogRef.close(null)">
                        {{ 'common_inputs.close' | translate }}</button> 
            </div>
        </div>
    </div>
</div>