import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogOverview } from '../../popup/contacto/contacto-popup.component';

@Component({
  selector: 'app-banner',
  templateUrl: './contacto-banner.component.html',
  styleUrls: ['./contacto-banner.component.scss']
})

export class ContactoBannerComponent implements OnInit {

  surname: string;
  name: string;

  constructor(public dialog: MatDialog,
    private translate: TranslateService
  ) {
    translate.setDefaultLang('pt');
  }

  openDialog(): void {
    window.scrollTo(0, 0);
    const dialogRef = this.dialog.open(DialogOverview, {
      width: '50vw',
      data: {}
    });

    dialogRef.updatePosition({ top: '0px' });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Esta función envía el formulario');

    });
  }
  ngOnInit() {
  }


  useLanguage(language: string) {
    this.translate.use(language);
  }

}
