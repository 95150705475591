import { RouterModule, Routes } from '@angular/router';
import { Pagina404Component } from './pages/404/pagina-404.component';


const appRoutes: Routes = [
  {path: '**', component: Pagina404Component}
];

export const APP_ROUTS = RouterModule.forRoot(appRoutes, { useHash:true} );

