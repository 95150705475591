import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Ciudad } from 'src/app/model/empresa/ciudad';
import { DepartamentoModel } from 'src/app/model/empresa/departamento';
import { TipoEmpresaModel } from 'src/app/model/empresa/tipoEmpresa';
import { BuscarEmpresaService } from 'src/app/pages/empresa/buscar-empresa/buscar-empresa.service';
import { GlobalService } from 'src/app/services/global.service';
import { RecaptchaService } from 'src/app/services/recaptcha.service';
import { DataService } from 'src/app/shared/data.service';
import Swal from 'sweetalert2';

export interface Criterio {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-empresa-acordeon',
  templateUrl: './empresa-acordeon.component.html',
  styleUrls: ['./empresa-acordeon.component.scss'],
  providers: [BuscarEmpresaService, RecaptchaService, GlobalService]
})
export class EmpresaAcordeonComponent implements OnInit {

  departamentoCtrl = new FormControl({ value: 'Rio Grande do Norte', disabled: true });
  filteredDepartamento: Observable<DepartamentoModel[]>;
  ciudadCtrl = new FormControl();
  filteredCiudad: Observable<Ciudad[]>;

  disabled = false;

  public listaDepartamentos = [];
  public listaCiudad = [];
  public listaTiposEmpresa: TipoEmpresaModel[] = [];
  company: FormGroup;
  selectedCriteria: string = "0";
  selectedTipoEmpresa: string;
  selectedDepartamento: string = 'Rio Grande do Norte';
  selectedCiudad: string;
  selectedCNPJ: string;
  selectedTodos: boolean = true;
  criteriaSelecionado: Criterio;
  public estadoDefault;

  DEPARTAMENTO_DEFAULT = "eyJhbGciOiJSUzI1NiJ9.eyJqdGkiOiIzOTMifQ.PFjrXjD2IGvCg8yLt6H3jYc5C5JYwUjOaHgBxaZm1EkAqM7tQDBAf7G_eJP253_4aOyIASII7kt30xfGjEb6B0FAng8vtfy1s2g5Wi5ct5YwV1vhqD_c2s7bD5BZkwn-RaUROxZsEDA8OaznAZxwWVnb8n2SrtDrti0sBcYXeMdu0fEGCVFn9ZKLQ2de6djuR1Mfj89vp1btwl7lUmdc_Dvq3lTVqE1QEhnQrnh1xfxYpFvgIW7tJcM5tYLVvV29RwHAOOTTQmPftKZCrdkFdqsu1V1vIqvJUhlurRxFSVM1TShqVbkwJNTke3ZlltSdgFrO6n825JIiNZwNArDrFw"

  constructor(
    private translate: TranslateService,
    private router: Router,
    private _dataSearchCompany: DataService,
    private _recaptchaService: RecaptchaService,
    private _globalService: GlobalService
  ) { }

  criteria: Criterio[] = [
    { value: '0', viewValue: 'Atividade' },
    { value: '1', viewValue: 'CNPJ' },
    { value: '2', viewValue: 'Todas as empresas' },
  ];

  ngOnInit() {   

    this._globalService.findValorParametroById(this.DEPARTAMENTO_DEFAULT).then((parametroEstadoDefault: any) => {
      this.estadoDefault = { idEstado: parametroEstadoDefault }; 
      this.findListaCiudadYEstado(parametroEstadoDefault);
      this.findTiposEmpresa();     
      this.onSelectDepartamento(this.estadoDefault )
    })
       
  }

  get f() { return this.company.controls; }

  findTiposEmpresa() {

    this._globalService.findTiposEmpresa().then((response: any) => {
      this.listaTiposEmpresa = response;
      /*this.listaTiposEmpresa.push({
        idTipoEmpresa: 0,
        nombreEmpresa: "Todas Atividades",
      });*/

    },
      error => {
        console.log(error);
      }
    );

  }

  findListaCiudadYEstado(idDepartamento: string) {
    this._globalService.findListaCiudad(idDepartamento).then((
      response: any) => {

      this.listaCiudad = response;
      if (this.disabled) {
        this.selectedCiudad = this.listaCiudad[0].nombreCiudad;
      }

      this.filteredCiudad = this.ciudadCtrl.valueChanges
        .pipe(
          startWith(''),
          map(ciudad => ciudad ? this._filterCiudades(ciudad) : this.listaCiudad.slice())
        );

    },

      error => {
        console.log(error);
      }
    );

    this._globalService.findListaEstado().then((
      response: any) => {

      this.listaDepartamentos = response;

      this.filteredDepartamento = this.departamentoCtrl.valueChanges
        .pipe(
          startWith(''),
          map(departamento => departamento ? this._filterDepartamentos(departamento) : this.listaDepartamentos.slice())
        );

    },

      error => {
        console.log(error);
      }
    );

  }

  onSelectCriteria() {
    this.selectedTipoEmpresa = '';
    //this.selectedDepartamento = '';
    this.selectedCiudad = '';
    this.selectedCNPJ = '';
  }

  onSelectTipoEmpresa(tipoEmpresa) {
    this._dataSearchCompany.cambiarBuscarEmpresaData(tipoEmpresa.idTipoEmpresa, null, null, null);
  }

  onSelectDepartamento(departamento) {
    this._dataSearchCompany.cambiarBuscarEmpresaData(null, departamento.idEstado, '', null);
    this.findListaCiudadYEstado(departamento.idEstado);
  }

  onSelectCiudad(ciudad) {
    this._dataSearchCompany.cambiarBuscarEmpresaData(null, null, ciudad.idCiudad, null, false);
  }

  onChangeCheckbox() {

    if (this.disabled) {
      this._dataSearchCompany.idState.subscribe(idEstado => {
        this.findListaCiudadYEstado(idEstado);
      });
    }
  }

  findCompany(captchaResponse: string) {
    if (this.selectedCriteria == "0") {
      if (this.disabled === false && this.selectedCiudad === undefined || (this.selectedTipoEmpresa == '' || this.selectedTipoEmpresa == null || this.selectedDepartamento == '' || this.selectedCiudad == '')) {
        this.getSwalError();
      } else {
        this.router.navigate(['pesquisar-empresa'])
        this._recaptchaService.VerificarRespuesta(captchaResponse).then((
          response:any) => {
            if (response) {
              this.router.navigate(['pesquisar-empresa'])
            }
            grecaptcha.reset();
          },
          error => {
            console.log(error);
          }
        );

      }
    } else if (this.selectedCriteria == "1") {
      if (this.selectedCNPJ == '') {
        this.getSwalError();
      } else {
        this._recaptchaService.VerificarRespuesta(captchaResponse).then((
          response:any) => {
            if (response) {
              this._dataSearchCompany.cambiarBuscarEmpresaData('', '', '', this.selectedCNPJ);
              this.router.navigate(['pesquisar-empresa'])
            }
            grecaptcha.reset();
          },
          error => {
            console.log(error);
          }
        );
      }
    } else if (this.selectedCriteria == "2") {
      this._recaptchaService.VerificarRespuesta(captchaResponse).then((
        response:any) => {
          if (response) {
            this._dataSearchCompany.cambiarBuscarEmpresaData('', '', '', '', this.selectedTodos);
            this.router.navigate(['pesquisar-empresa'])
          }
          grecaptcha.reset();
        },
        error => {
          console.log(error);
        }
      );
    } else {
      this.getSwalError();
    }
  }

  getSwalError() {
    if (this.selectedCriteria != null) {
      this.translate.get('search.inputs.company.sweetAlert.empty.title').subscribe((title: string) => {
        this.translate.get('search.inputs.company.sweetAlert.empty.message').subscribe((message: string) => {
          Swal.fire(title, message, "warning");
        });
      });
    } else {
      this.translate.get('search.inputs.company.sweetAlert.title').subscribe((title: string) => {
        this.translate.get('search.inputs.company.sweetAlert.message').subscribe((message: string) => {
          Swal.fire(title, message, "warning");
        });
      });
    }
  }


  private _filterDepartamentos(value: string): DepartamentoModel[] {
    const filterValue = value.toLowerCase();
    return this.listaDepartamentos.filter(departamento => departamento.nombreDepartamento.toLowerCase().indexOf(filterValue) === 0);
  }
  private _filterCiudades(value: string): Ciudad[] {
    const filterValue = value.toLowerCase();
    return this.listaCiudad.filter(ciudad => ciudad.nombreCiudad.toLowerCase().indexOf(filterValue) === 0);
  }

  setCriterio(criterio: Criterio): void {
    if (criterio.value == '2') {
      this.criteriaSelecionado = criterio;
    } else {
      this.criteriaSelecionado = undefined;
    }
  }

}
