import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { map }  from 'rxjs/operators'
import { GLOBAL } from '../../../shared/parametrosGlobales';
import { CriteroBusquedaPiezaModel } from 'src/app/model/pieza/criterioBusquedaPieza';
import { ConfigService } from 'src/app/services/config.service';

@Injectable()
export class BuscarPiezaService {

    public url: string = ConfigService.settings.env;
    public path: string;

    constructor(public _http: Http) {
    }

    public findMarcasTipoVehiculo(idTipoVehiculo: string) {
        this.path = GLOBAL.global.getMarcasByTipoVehiculo+idTipoVehiculo;
        return this._http.get(this.url + this.path, {}).pipe(map(res => res.json()));
    }

    public findTipoVehiculo() {
        this.path = GLOBAL.global.getTiposVehiculo;
        return this._http.get(this.url + this.path, {}).pipe(map(res => res.json()));
    }

    public findMarca(marcaMasBuscada: string) {
        this.path = GLOBAL.global.getMarcas;
        return this._http.get(this.url + this.path + marcaMasBuscada, {}).pipe(map(res => res.json()));
    }

    public findReferenciaComercial(idMarca: string, idReferenciaComercial: string) {

        let criterioDeBusquedaRefcomercial = {
            "idMarca": idMarca,
            "idReferenciaComercial": idReferenciaComercial
        };

        this.path = GLOBAL.global.getReferenciaComercial;
        return this._http.post(this.url + this.path, criterioDeBusquedaRefcomercial).pipe(map(res => res.json()));
    }

    public findPieza(idTipoVehiculo: string, idTipoPieza: string, idMarca: string, idModelo: string) {

        let criterioDeBusquedaPieza = {
            "idTipoVehiculo": idTipoVehiculo,
            "idTipoPieza": idTipoPieza,
          "idMarca": idMarca,
          "idModelo": idModelo,
        };

        this.path = GLOBAL.global.getPiezaPorTipoVehiculo;
        return this._http.post(this.url + this.path, criterioDeBusquedaPieza).pipe(map(res => res.json()))
    }

    public findEmpresa(criterioBusquedaPieza: CriteroBusquedaPiezaModel) {

        this.path = GLOBAL.pieza.buscar.getPiezaEmpresa;
        return this._http.post(this.url + this.path, criterioBusquedaPieza).pipe(map(res => res.json()));
    }

    public findMarcaMasBuscada() {
        this.path = GLOBAL.global.getMarcasMasBuscadas;
        return this._http.get(this.url + this.path, {}).pipe(map(res => res.json()));
    }

    public RefComercialMasBuscadas(idMarca: string) {
        this.path = GLOBAL.global.getRefComercialMasBuscadas;
        return this._http.get(this.url + this.path + idMarca, {}).pipe(map(res => res.json()));
    }

    public findPiezaMasVendida(idTipoVehiculo: string, idMarca: string, idModelo: string) {
        this.path = GLOBAL.global.getPiezasMasBuscadas;
        return this._http.get(this.url + this.path + idTipoVehiculo + '&idMarca='+idMarca + '&idModelo='+idModelo, {}).pipe(map(res => res.json()));
    }
}
