<div class="banner container mb-3">
  <div class="banner-img row">
    <div class="col-md-6 col-12 ">
    </div>
    <div class="col-md-6 col-12 d-flex justify-content-center">
      <div class="banner-link pt-4 px-4">
        <div class="mt-3">
        <p>
          {{ 'banner.contactBanner.message' | translate }}
        </p>
        </div>


      </div>

    </div>
  </div>
  <div class="row py-2">
      <div class="banner-msg d-sm-flex d-md-flex d-lg-flex align-items-end col-md-6 col-12">
        <h3 class="mb-1">
          {{ 'banner.contactBanner.title' | translate }}
        </h3>
      </div>
      <div class="col-md-6 col-12 d-flex justify-content-end ">
        <button (click)="openDialog()" class="d-flex justify-content-center btn btn-primary banner-link__btn px-4">
          {{ 'banner.contactBanner.btn' | translate }}
        </button>
      </div>
  </div>

</div>
