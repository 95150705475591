<div class="row">
  <div class="container">
    <div style="margin-top:40px;" class="col-12">
      <h3>{{ 'searchCompany.title' | translate }}</h3>
      <p>{{ 'searchCompany.message' | translate }}</p>
    </div>
    <div style="margin-top:20px;" class="col-12" #TABLE>
      <div style="text-align: center" class="row">
        <div class="col-8">
          <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)"
              placeholder="{{ 'searchCompany.filter' | translate }}">
          </mat-form-field>
        </div>
        <div style="margin-top:10px" class="col-4">
          <button mat-raised-button class="btn btn-primary" (click)="ExportTOExcel()">{{ 'searchCompany.export' | translate
            }}</button>
        </div>
      </div>

      <section style="overflow-x: auto;">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
          <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
            <th mat-header-cell *matHeaderCellDef> {{'common_inputs.'+column | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element[column]}}</td>
          </ng-container>
          <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button aria-label="expand row" class="btn btn-primary"
                (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon><strong></strong>
              </button>
            </td>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
              <div class="example-element-detail"
                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <h5><strong>{{'common_inputs.detalleActivity' | translate }}</strong></h5>
                <div class="row mt-2">
                  <div class="col-3"><strong>{{ 'common_inputs.activity' | translate }}:</strong>
                    {{element.activityCompany}}</div>
                  <div class="col-3"><strong>{{ 'common_inputs.address' | translate }}</strong>
                    {{element.direccionCompleta}}, {{element.numeroVia}}</div>
                  <div class="col-2"><strong>{{ 'common_inputs.neighborhood' | translate }}:</strong> {{element.barrio}}
                  </div>
                  <div class="col-2"><strong>{{ 'common_inputs.city' | translate }}:</strong> {{element.city}} </div>
                  <div class="col-2"><strong>{{ 'common_inputs.state' | translate }}:</strong> {{element.state}}</div>
                </div>
                <div class="row my-2 ">
                  <div class="col-2"><strong>{{ 'common_inputs.CEP' | translate }}:</strong> {{element.cep}}</div>
                </div>
                <h5><strong>{{'common_inputs.contactActivity' | translate }}</strong></h5>
                <div class="row mt-2 mb-4">
                  <div class="col-3"><strong>{{ 'common_inputs.email' | translate }}:</strong> {{element.email}}</div>
                  <div class="col-3"><strong>{{ 'common_inputs.telephone' | translate }}:</strong> {{element.telephone}}
                  </div>
                  <!--<div class="col-4">
                    <button mat-icon-button>Busca no mapa</button>
                  </div>-->
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
          <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
      </section>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
      </mat-paginator>
    </div>
  </div>
</div>