<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="small" color="#fff" type="ball-spin">
  <p style="font-size: 20px; color: white">{{ 'searchPart.spinner' | translate }}</p>
</ngx-spinner>
<div class="row">
  <div class="container">
    <div style="margin-top:40px;" class="col-12">
      <h3>{{ 'searchPart.title' | translate }}</h3>
      <p>{{ 'searchPart.message' | translate }}</p>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="container">
      <div class="col-md-12 order-md-2 mb-4 d-block ">
        <div class="col-md-12 iconos d-flex flex-row">
          <div class="col-md-3 mt-4 etiqueta">
            <div class="etiqueta--color p-2">
              <div class="etiqueta--icono">
                <em *ngIf="tipoVehiculo == 'Automóvel'" class="fas fa-car"></em>
                <em *ngIf="tipoVehiculo == 'Caminhão'" class="fas fa-truck"></em>
                <em *ngIf="tipoVehiculo == 'Motocicleta'" class="fas fa-motorcycle"></em>
                <em *ngIf="tipoVehiculo == 'Ônibus'" class="fas fa-bus"></em>
              </div>
              <div> {{ 'searchPart.part.typeVehicle' | translate }} </div>
              <p class=" etiqueta--texto fs-14"> {{tipoVehiculo}} </p>
            </div>
          </div>
          <div class="col-md-3 mt-4 etiqueta">
            <div class="etiqueta--color p-2">

              <div class="etiqueta--icono">
                <em *ngIf="tipoVehiculo == 'Automóvel'" class="fas fa-car"></em>
                <em *ngIf="tipoVehiculo == 'Caminhão'" class="fas fa-truck"></em>
                <em *ngIf="tipoVehiculo == 'Motocicleta'" class="fas fa-motorcycle"></em>
                <em *ngIf="tipoVehiculo == 'Ônibus'" class="fas fa-bus"></em>
              </div>
              <div> {{ 'searchPart.part.brand' | translate }} </div>
              <p class=" etiqueta--texto fs-14"> {{marca}} </p>
            </div>
          </div>
          <div class="col-md-3 mt-4 etiqueta">
            <div class="etiqueta--color p-2">

              <div class="etiqueta--icono">
                <em *ngIf="tipoVehiculo == 'Automóvel'" class="fas fa-car"></em>
                <em *ngIf="tipoVehiculo == 'Caminhão'" class="fas fa-truck"></em>
                <em *ngIf="tipoVehiculo == 'Motocicleta'" class="fas fa-motorcycle"></em>
                <em *ngIf="tipoVehiculo == 'Ônibus'" class="fas fa-bus"></em>
              </div>
              <div> {{ 'searchPart.part.model' | translate }} </div>
              <p class=" etiqueta--texto fs-14"> {{modelo}} </p>
            </div>
          </div>

          <div class="col-md-3 mt-4 etiqueta">
            <div class="etiqueta--color p-2">

              <div class="etiqueta--icono"> <em class="fas fa-screwdriver"></em> </div>
              <div> {{ 'searchPart.part.part' | translate }} </div>
              <p class=" etiqueta--texto fs-14"> {{pieza}} </p>
            </div>
          </div>
        </div>
        <br>
        <div class="col-8">
          <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)"
              placeholder="{{ 'searchCompany.filter' | translate }}">
          </mat-form-field>
        </div>
        <div style="overflow-x: auto;">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="nombreSede">
              <th mat-header-cell *matHeaderCellDef> {{ 'common_inputs.socialReason' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.nombreSede}} </td>
            </ng-container>

            <ng-container matColumnDef="direccionCompleta">
              <th mat-header-cell *matHeaderCellDef> {{ 'common_inputs.address' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.direccionCompleta}}</td>
            </ng-container>

            <ng-container matColumnDef="barrio">
              <th mat-header-cell *matHeaderCellDef> {{ 'common_inputs.neighborhood' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.barrio}} </td>
            </ng-container>

            <ng-container matColumnDef="nombreCiudad">
              <th mat-header-cell *matHeaderCellDef> {{ 'common_inputs.city' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.nombreCiudad}} </td>
            </ng-container>

            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef> {{ 'common_inputs.email' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container>

            <ng-container matColumnDef="telefono">
              <th mat-header-cell *matHeaderCellDef> {{ 'common_inputs.phone' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.telefono}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[10, 20, 100]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>