import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { map }  from 'rxjs/operators'
import { ConfigService } from 'src/app/services/config.service';
import { GLOBAL } from '../../../shared/parametrosGlobales';


@Injectable()
export class VerifyPartService {

  public url: string =  ConfigService.settings.env;
  public path: string;

  constructor(public _http: Http) { }


  public findPiezaPorCodigo(codigoPieza: string) {

    this.path = GLOBAL.pieza.verificar.getInformacionPiezaPorCodigo
    
    return this._http.get(this.url + this.path + codigoPieza, {}).pipe(map(res => res.json()));
  }
}

