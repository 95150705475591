<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="small" color="#fff" type="ball-spin">
  <p style="font-size: 20px; color: white">{{ 'searchPart.spinner' | translate }}</p>
</ngx-spinner>


<!-- Banner -->
<div class="banner-back">
  <div class="row">
    &nbsp;
  </div>
  <div class="row">
    <div class="container" style="color: white;">
      <h3>{{ 'search.inputs.complaint.registerDenuncia' | translate }}</h3>
    </div>
    <div class="container" style="color: white;">
      <h6>{{ 'complaint.Subtitle' | translate }}</h6>
    </div>
  </div>
</div>

<!--Denunciante-->
<div class="container d-flex mt-5">
  <form name="form" (ngSubmit)="f.form.valid && guardarDenuncia($event)" #f="ngForm">
    <!--<re-captcha #captchaRef="reCaptcha" (resolved)="guardarDenuncia($event)"></re-captcha>-->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header bg-primary text-white font-weight-bold">
            {{ 'complaint.complainant' | translate }}
          </div>
          <div class="card-body">
            <div class="form-row d-flex justify-content-center pt-2">
              <div class="col-md-3 col-12">

                <mat-form-field>
                  <input matInput placeholder=" {{ 'complaint.complainant' | translate }}"
                    [(ngModel)]="denunciaModel.denunciante" name="denunciante" id="denunciante">
                </mat-form-field>
              </div>
              <div class="col-md-3 col-12">
                <mat-form-field>
                  <input matInput placeholder="{{ 'common_inputs.email' | translate }}" [formControl]="email"
                    name="email" id="email" [(ngModel)]="denunciaModel.email">
                  <mat-error *ngIf="email.invalid">{{ getErrorMessage()  | translate }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-2 col-12">
                <mat-form-field>
                  <input matInput placeholder="{{ 'common_inputs.DDD' | translate }}" name="DDD" id="DDD"
                    [(ngModel)]="denunciaModel.ddd" maxlength="3">

                </mat-form-field>

              </div>
              <div class="col-md-2 col-12">
                <mat-form-field>
                  <input matInput placeholder="{{ 'common_inputs.telephone' | translate }}" name="telefono"
                    id="telefono" [(ngModel)]="denunciaModel.telefono" maxlength="9">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Direccion del denunciante-->

    <div class="row  mt-3">
      <div class="col-12">
        <div class="card">
          <div class="card-header bg-primary text-white font-weight-bold">
            {{ 'complaint.titleAddress' | translate }}
          </div>
          <div class="row d-flex justify-content-center pt-2">
            <div class="col-md-2 col-10">
              <mat-form-field class="example-full-width ml-3">
                <input placeholder="{{ 'common_inputs.CEP' | translate }}" name="cep" id="cep" readonly="true"  [formControl]="controlCep"
                  [(ngModel)]="denunciaModel.cep" [ngClass]="{ 'is-invalid': f.submitted && cep.invalid }" #cep matInput required readonly>
                <mat-error *ngIf="controlCep.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
              </mat-form-field>

            </div>
            <div class="col-md-1 col-2 mt-3">
              <mat-icon (click)="openCepDialog()">search</mat-icon>
            </div>
            <div class="col-md-3 col-12">
              <mat-form-field style="width:100%">
                <input matInput type="text" [formControl]="controlDepartamento" placeholder="{{ 'common_inputs.state' | translate }}" aria-label="State" #nombreDepartamento
                  [(ngModel)]="denunciaModel.nombreDepartamento" [ngClass]="{ 'is-invalid': f.submitted && nombreDepartamento.invalid }" name="nombreDepartamento" id="nombreDepartamento"
                  readonly required>
                  <mat-error *ngIf="controlDepartamento.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-3 col-12">
              <mat-form-field>
                <input matInput placeholder="{{ 'common_inputs.neighborhood' | translate }}" name="barrio" id="barrio"
                  [(ngModel)]="denunciaModel.barrio" readonly>
              </mat-form-field>
            </div>

            <div class="form-row d-flex justify-content-center">

              <div class="col-md-3 col-12">
                <mat-form-field>
                  <input matInput [formControl]="controlNumeroDirreccion" placeholder="{{ 'common_inputs.number' | translate }}" name="numero" id="numero"
                    [(ngModel)]="denunciaModel.numero" #numero
                    [ngClass]="{ 'is-invalid': f.submitted && numero.invalid }" required>
                  <mat-error *ngIf="controlNumeroDirreccion.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-3 col-12">
                <mat-form-field style="width:100%">
                  <input [formControl]="controlCiudades" matInput type="text"
                    placeholder="{{ 'common_inputs.city' | translate }}" [matAutocomplete]="autoCiudad"
                    [(ngModel)]="selectedCiudad" name="ciudad" #ciudad
                    [ngClass]="{ 'is-invalid': f.submitted && ciudad.invalid }" required readonly>
                  <mat-autocomplete #autoCiudad>
                    <mat-option (click)="onSelectCiudad(controlCiudades)" *ngFor="let controlCiudades of filteredCiudad | async"
                      [value]="controlCiudades.nombreCiudad">
                      <span>{{controlCiudades.nombreCiudad}}</span>
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="controlCiudades.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-12">
                <mat-form-field>
                  <input matInput placeholder="{{ 'common_inputs.address' | translate }}" name="direccion"
                    id="direccion" [(ngModel)]="denunciaModel.direccion" readonly required>
                    <mat-error *ngIf="controlNumeroDirreccion.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-3 col-12">
                <mat-form-field>
                  <input matInput placeholder="{{ 'common_inputs.complement' | translate }}" name="complemento"
                    id="complemento" [(ngModel)]="denunciaModel.complemento">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Identificacion denunciado-->
      <div class="container mt-3">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header bg-primary text-white font-weight-bold">
                {{ 'complaint.denouncedIdentification' | translate }}
              </div>
              <div class="card-body">
                <div class="form-row d-flex justify-content-center pt-2">
                  <div class="col-md-3 col-12">
                    <mat-form-field style="width:100%">
                      <mat-select placeholder="{{ 'common_inputs.identificationType' | translate }}">
                        <mat-option>--</mat-option>
                        <mat-option (click)="onSelectDocument(document)" *ngFor="let document of ListaDocumentos"
                          [value]="document.nombreDocumento">
                          {{document.nombreDocumento}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3 col-12">
                    <mat-form-field>
                      <input matInput placeholder="{{ 'common_inputs.identificationNumber' | translate }}"
                        name="identificationNumber" id="identificationNumber"
                        [(ngModel)]="denunciaModel.numeroIdentificacion">
                    </mat-form-field>
                  </div>
                  <div class="col-md-3 col-12">
                    <mat-form-field>
                      <input matInput placeholder="{{ 'complaint.companyName' | translate }}" name="companyName"
                        id="companyName" [(ngModel)]="denunciaModel.nombreEmpresa">
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Denuncia-->
      <div class="container mt-3">
        <div class="card">
          <div class="card-header bg-primary text-white font-weight-bold">
            {{ 'complaint.complaint' | translate }}
          </div>
          <div class="form-row d-flex justify-content-center">
            <div class="col-md-9">
              <mat-form-field class="example-full-width" appearance="outline" hintLabel="Max 499 characters">
                <textarea matInput #denuncia maxlength="459" placeholder="{{ 'complaint.complaint' | translate }}"
                  name="complaint" id="complaint" [(ngModel)]="denunciaModel.denuncia"
                  style="min-height: 130px;" [ngClass]="{ 'is-invalid': f.submitted && denuncia.invalid }"
                  required></textarea>
                <mat-error *ngIf="denuncia.invalid">{{ 'common_inputs.requieredField' | translate }}</mat-error>
                <mat-hint align="end">{{denuncia.value?.length || 0}}/{{459-denuncia.value?.length}}
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <!--Anexos-->
      <div class="col-12 mt-3">
        <div class="card">
          <div class="card-header bg-primary text-white font-weight-bold">
            {{ 'common_inputs.annexes' | translate }}
          </div>
          <div class="card-body">
            <div _ngcontent-c3="" class="card-header bg-secondary text-white ">
              {{ 'complaint.attachedMessage' | translate }}</div>
            <div class="m-0 px-1 pt-2 mt-2">
              <form [formGroup]="uploadForm">
                <input style="display:none" formControlName="document" type="file" ng2FileSelect
                  accept=".pdf,.jpg,.png,.doc" [uploader]="uploader" #fileInput multiple (change)="uploadSubmit()" />
                <button type="button" class="btn btn-outline-primary btn-sm" (click)="fileInput.click()">
                  <span class="d-none d-sm-block">{{ 'complaint.add' | translate }}</span>
                </button>

                <br />
                <table class="mt-3">
                  <tbody>
                    <tr *ngFor="let item of uploader.queue">
                      <th>
                        {{ item.file.name}}({{item.file.size/1000000}} MB)
                      </th>
                      <th class="text-center">
                        <button mat-button>
                          <mat-icon (click)="item.remove(); uploadSubmit();">delete</mat-icon>
                        </button>
                      </th>
                    </tr>
                  </tbody>
                </table>

              </form>
            </div>
            <!-- Docs -->
          </div>
        </div>
      </div>
      <div class="col-12 mt-3">
      </div>
    </div>
    <div class="row d-flex justify-content-center mt-3">
      <button type="button" (click)="backToHome()" class="btn btn-outline-primary btn-lg mr-3">
        {{ 'contactUs.buttons.back' | translate }}
      </button>
      <button type="submit" class="btn btn-primary btn-lg" [disabled]="enviado">
        {{ 'common_inputs.send' | translate }}
      </button>
    </div>
  </form>
</div>
