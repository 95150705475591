import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
//import { NgxSpinnerService } from 'ngx-spinner';
import { EmpresaPiezaModel } from 'src/app/model/pieza/empresaPieza';
import { BuscarPiezaService } from 'src/app/pages/pieza/buscar-pieza/buscar-pieza.service';
import { DataService } from 'src/app/shared/data.service';
import { CriteroBusquedaPiezaModel } from 'src/app/model/pieza/criterioBusquedaPieza';
import Swal from 'sweetalert2';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-buscar-pieza',
  templateUrl: './buscar-pieza.component.html',
  styleUrls: ['./buscar-pieza.component.scss'],
  providers: [BuscarPiezaService]
})
export class BuscarPiezaComponent implements OnInit {

  criterioBusquedaPieza : CriteroBusquedaPiezaModel;

  tipoVehiculo: any;
  marca: any;
  modelo: any;
  pieza: any;

  displayedColumns = ['nombreSede', 'direccionCompleta', 'barrio', 'nombreCiudad', 'email', 'telefono'];
  dataSource;

  constructor(
    private router: Router,
    private _buscarPiezaService: BuscarPiezaService,
    breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    private _dataBuscarPiezaEmpresa: DataService,
    //private spinner: NgxSpinnerService
  ) {
    translate.setDefaultLang('pt');
    this.criterioBusquedaPieza = new CriteroBusquedaPiezaModel("","","","");
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngOnInit() {

    /** spinner starts on init */
    //this.spinner.show();

    this._dataBuscarPiezaEmpresa.idTipoVehiculo.subscribe(idTipoVehiculo => {
      idTipoVehiculo != '' ? this.criterioBusquedaPieza.idTipoVehiculo = idTipoVehiculo : this.criterioBusquedaPieza.idTipoVehiculo = null
    });

    this._dataBuscarPiezaEmpresa.idMarca.subscribe(idMarca => {
      idMarca != '' ? this.criterioBusquedaPieza.idMarca = idMarca : this.criterioBusquedaPieza.idMarca = null
    });

    this._dataBuscarPiezaEmpresa.idModel.subscribe(idModel => {
      idModel != '' ? this.criterioBusquedaPieza.idReferenciaComercial = idModel : this.criterioBusquedaPieza.idReferenciaComercial = null
    });

    this._dataBuscarPiezaEmpresa.idPart.subscribe(idPart => {
      idPart != '' ? this.criterioBusquedaPieza.idTipoParte = idPart : this.criterioBusquedaPieza.idTipoParte = null
    });

    let ELEMENT_DATA: EmpresaPiezaModel[] = [];

    this._buscarPiezaService.findEmpresa(this.criterioBusquedaPieza).subscribe(
      response => {
        console.log("ingresa a configurar la lista");
        //this.spinner.hide();

        if (response.length > 0) {
          this.tipoVehiculo = response[0].nombreTipo;
          this.marca = response[0].nombreMarca;
          this.modelo = response[0].nombreReferencia.toLowerCase();
          this.modelo = this.modelo[0].toUpperCase() +  this.modelo.substring(1);
          this.pieza = response[0].nombrePieza;
          ELEMENT_DATA = response;
          this.dataSource = new MatTableDataSource(ELEMENT_DATA);
          this.dataSource.paginator = this.paginator;
        } else {
          this.translate.get('common_inputs.sweetAlert.error.title').subscribe((title: string) => {
            this.translate.get('common_inputs.sweetAlert.error.message').subscribe((message: string) => {
              Swal.fire(title, message, "error").then((value) => {
                this.router.navigate(['home'])
              });
            });
          });
        }
      },
      error => {
        //this.spinner.hide();
        console.log(error);
        this.translate.get('common_inputs.sweetAlert.error.title').subscribe((title: string) => {
          this.translate.get('common_inputs.sweetAlert.error.message').subscribe((message: string) => {
            Swal.fire(title,message,"error").then((value) =>{
              this.router.navigate(['home'])
            });
          });
        });
      }
    );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
